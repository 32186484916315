.vw-page-subtitle {
  font-family: VWHeadOffice;
  color: #000000;
  padding-bottom: 25px;
  font-size: 1.8rem;
}

.vw-page-subtitle-no-value {
  font-family: VWHeadOffice;
  color: #000000;
  padding-bottom: 80px;
  font-size: 1.8rem;
}