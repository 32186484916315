/* APPROVAL DETAIL */
.vw-approval-backoffice .loan-data {
  border-bottom: 0px solid #666;
}
.agent-details-wrapper {
  background-color: #f2f2f2;
  height: 100%;
}
.vw-label .form-label-group {

}

.vw-label .form-label-group textarea {
  border:none;
  height: 80px;
  resize: none;
  box-shadow: none;
}

.radio-car-title {
  font-size: 2rem;
  font-weight: bold;
  color:#000 !important;
}

.radio-car-item {
  padding-top: 2rem;
  padding-bottom:2rem;
  border-bottom:1px solid #e3e5e6
}
.radio-car-title + .loan-user p{
  color:#888 !important;
}

.radio-car-desc {
  color: #888;
  font-size: 1.6rem
}

.detailResume-title {
  color: #000;
  font-size: 2.6rem;
  font-weight: bold;
}

.detailResume-item {
  color: #888;
}

.detailResume-item-value {
  color: #000;
}

.vw-approval-backoffice .modal-container .decision-buttons button {
  font-size: 1.9rem;
}

.vw-approval-backoffice .btn-primary:disabled,
.btn-primary:disabled {
  color: #666 !important;
  background-color: #7f8991;
  border-color: #7f8991;
}

.openModalCNH {
  color: #0099DA;
  background-image: url('./images/driving-license.svg');
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: left;
  border: none;
  outline: none!important;
  padding: 3px 0 3px 42px;
}

.cnhPreviewModal {
  max-width: 80vw;
  max-height: 60vh;
  text-align: center;
  overflow: hidden;
  margin-bottom: 2rem;
}

.cnhPreviewModal img {
  max-width: 98%;
  max-height: 98%;
  margin:4rem auto 1rem auto !important;
}

@media (max-width: 544px) {
  .vw-approval-backoffice .loan-data {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 1rem;
  }
}