.holder-video,
.holder-image{
    width: 100%;
    height: auto;
    position: relative; 
    overflow: hidden;
    cursor: pointer;
    /* height: 23vh; */
}
.holder-video .play-btn,
.expand__imageHeader .play-btn {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    background:url('./img/play-button.svg') center center no-repeat ;
    background-size: 20% 20%;
    opacity: 0.8;
    display: block;
    z-index: 30;
}
.holder-video .hidden-btn,
.expand__imageHeader .hidden-btn {
    display: none;
}
.holder-video video,
.holder-video iframe,
.holder-image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    object-fit: cover;
    object-position: 50% 50%;
}

.holder-video iframe {
    min-height: 240px;
    border:none;
}