
.contactRow__title{
  margin: 22.5px 0px;
  font-family: VWHeadOffice, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.contactRow__container .page-title {
  font-size: 2.9rem;
}
