.Speak-container {
    padding-bottom: 2rem;
}

.container-fluid .Speak-container {
    padding: 2rem;
    width: calc(100vw - 13rem);
    margin-left: 1.6rem;
}
.vw-speak .vw-bodys {
    padding-left: 0rem !important;
    margin-left: 0rem !important;
    background: none;
    width: 100%;
}
.container .vw-speak  {
    margin:3rem -32rem 0 0;
}

.container-fluid .vw-speak  {
    margin:3rem 10rem 0 0;
    background-color:none;
}
.vw-speak .container-fluid .col-lg-3{
    

}
.vw-speak .container .col-lg-3{
    /* flex: 0 0 35%;
    max-width: 35%; */
}
.Speak-container .w-100 {
    margin-bottom: 2rem;
}
.car-title{
    margin-left: 1.5rem;
}


@media(max-width: 1280px){
    .container .vw-speak  {
        margin:3rem -32rem 0 0;
    }
    
    .container-fluid .vw-speak  {
        margin:3rem 5rem 0 0;
        background-color:none;
    }
   
}
@media (max-width: 992px) { 
    .container-fluid .Speak-container {
        width: calc(100vw - 2rem);
        padding-top:0;
        margin-left: 1.4rem;
    }
    .container .vw-speak  {
        margin:3rem -32rem 0 0;
    }
    
    .container-fluid .vw-speak  {
        margin:3rem 2rem 0 0;
        background-color:none;
    }
}
@media (max-width: 544px) { 

    .Speak-container > .row .col-11 {
        margin: 0;
        margin-bottom:1rem;
        padding-right: 0rem;
    }
    /* .bgH{
        width: 16%;
    } */
}
.row img{
    border-radius: 5px;
    overflow: none;
}

@media (max-width:320px){
    /* .bgH{
        width: 195%;
    } */
}

