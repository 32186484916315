.loading-container {

}

.loading-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left:0;
    top:0;
    background: rgba(0,0,0,0.6) url('./img/loading.svg') center center no-repeat;
    background-size: 7vw auto;
    z-index: 1040;
    color:rgba(0,0,0,0);

}

