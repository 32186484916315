
.vw-registry-form{
    margin-top: 3rem;
}
.vw-registry-form  .vw-registry-newsletter {
  position: relative;
  top: -25px;
  left: 25px;
  color: #0199da;
}
.vw-registry-form .form-label-group label {
  /* left: 1rem; */
  font-family:VWTextOffice, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.vw-box-ckeckbox{
  margin-top: 5px;
}
.vw-registry-form{
    margin-top: 0;
}
.vw-checkbox-input {
  display: inline-block;
  margin-right: 10px;
}
.vw-checkbox-label {
  color: #0199da;
}
.vw-align-center {
  text-align: center !important;
}
.inputCheckForm {
  height: 15px !important;
}

.vw-registry-form .vw-floating-label .form-label-group.select-item {
  padding-bottom: .6rem !important;
}
.vw-registry-form .vw-floating-label .form-label-group select,
.vw-registry-form .vw-floating-label .form-label-group select.input-filled,
.vw-registry-form .vw-floating-label .form-label-group select.input-filled:focus,
.vw-registry-form .vw-floating-label .form-label-group select.input-filled:active {
 min-height: 30px;
 padding-bottom: 0 !important
}
