

.vw-internal-car{
    margin-top: 3rem;
}

  .vw-paragrafo-20{
      margin-top:30px;
  }
  .vw-imprint .vw-text-body{
    margin-top: -2rem;
    margin-bottom: 2rem;
}
.vw-imprint .alinhamento{
    font-family: VWHeadOffice;
    color: #000000;
    padding-bottom: 25px;
    font-size: 1.8rem;
    /* margin-top:-1rem; */
}
.alinhamento-check{
 top: 1rem;
}

.form-check-terms .vw-page-subtitle {
    padding-bottom: 0;
    margin-bottom: 3rem;
}

