.vw-breadcrumb {
    font-family: "VWTextOffice-Bold";
    font-size: 1.3rem;
    color: #909090;
    margin-top: 40px;
    position: absolute;
    z-index: 500;
}

.vw-breadcrumb + div {
    padding-top: 90px
}

@media (max-width: 554px) {
    .vw-breadcrumb {
        margin-top: 30px;
    }
    .vw-breadcrumb + div {
        padding-top: 70px
    }
}
