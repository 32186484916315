.newscomponenttitle {
  height: auto;
  width: 50vw;
  max-width: 100%;

}

.newscomponenttitle .title {
  font-family: VWHeadOffice-Bold;
}

@media (min-width: 544px) and (max-width: 768px){
  .newscomponenttitle {
    height: auto;
    width: 100vw;
    max-width: 100%;
  
  }
}

@media (max-width: 543px) {
  .newscomponenttitle {
    height: auto;
    width: 100vw;
    max-width: 100%;
  
  }
}
