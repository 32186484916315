.vw-tabbar {
    display: none;
    position: absolute;
    z-index: 999;
}
.vw-tabbar.fixed-top {
    position: fixed;
    top:0;
    margin-top: 0;
}
.vw-tabbar .navbar{
    margin-top: 0.5rem;
    padding:  0;
}

.vw-tabbar .navbar-nav {
    flex-direction: row;
    width: 100vw;
    box-shadow: 0 0.5px 3.5px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-bottom: 1px solid #ccc;
}  

.vw-tabbar .navbar .navbar-nav .nav-item {
    width: 25%;
    height: 50px;
    line-height: 40px;
    text-align: center;
    position: relative;
    padding-bottom: 2rem;
    text-transform: uppercase;
}
    .vw-tabbar .navbar .navbar-nav .nav-item.active {
        border-bottom: 3px solid #0199da;
    }
.vw-tabbar .navbar .navbar-nav .nav-item label {
    margin-left: 0 !important;
    margin-top: 0.5rem;
    font-size: 1.2rem;
    min-width: 30px;
    height: 6.5px;
    font-family: VWHeadOffice;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    text-align: center;
    color: #909090;
}   
    .vw-tabbar .navbar .navbar-nav .nav-item.active label {
        color: #0199da;
    }

.vw-tabbar .navbar .navbar-nav .nav-item .brand-icon{
    width: 32px;
    height: 32px;
    margin:0 0 0 -32px !important;
    left:50%;
    color: #7f8991;
}
.vw-tabbar .navbar .navbar-nav .nav-item .brand-icon:before {
    width: 32px;
    height: 32px;
    font-size:3.5rem;
    margin:-1rem 0 0 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
}

/** Cal vw-tabbar position 
* header height -> 70px (fixed)
* banner height -> 1080px (proportional)
* 100vw * 1080 / 1920
* margin-top -> height of Banner text + header height + logged In component height if is logged
*/
@media (max-width: 992px) {
    .vw-tabbar {
        display: block;
        top: calc((100vw * 1080) / 1920);
        margin-top:190px;
    }
    .vw-tabbar .navbar {
        margin-top: 0rem;
        padding: 0;
    }
}
@media (max-width: 768px) {
    .brand-icon.is-models:before {
        margin-right: 0 !important;
    }
    .vw-tabbar.is-logged-tabbar:not(.fixed-top) {
        margin-top:415px; 
    }
    .vw-tabbar .navbar .navbar-nav .nav-item .brand-icon:before {
        margin:-1rem 0 0 0 !important;
        margin-right: 0 !important;
    }
}
@media (max-width: 544px) {
    .vw-tabbar.is-logged-tabbar:not(.fixed-top) {
        margin-top:415px; 
    }
}

@media (max-width: 375px) {
    .vw-tabbar.is-logged-tabbar:not(.fixed-top) {
        margin-top:415px; 
    }
}
