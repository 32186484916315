.youtube-container {
  padding:3rem 0;
}

.youtube-container .btn-txt {
  /* font-size: 1.3rem; */
}
.youtube-container .newscomponent-item {
  margin-bottom: 3rem
}
.youtube-container .newscomponent-item .col-data .newscomponenttitle .title{
  font-size: 1.6rem !important
}
.youtube-container .newscomponent-item .title,
.youtube-container .newsItem-container .newsdate-component-before .vw-social .vw-date-info {
  font-weight: 100 !important;
}   

.youtube-container .holder-video {
  border-radius: 5px;
}  
.youtube-container .holder-video .rounded {
  border-radius: 5px;
  margin-bottom: -10px;
  /* max-width: 100%; */
}   
.homeMobile .youtube-container .newsItem-container .newscomponent-item {
  margin-bottom: 2rem;
}

/* @media (max-width: 544px) {
  .homeMobile .youtube-container .d-block {
    margin-left: 10vw !important
  }
} */