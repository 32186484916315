.txt-car{
    top:5rem;
}
.txt-car-menu{
    font-family: "VWText", sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8b8b8b;
    -webkit-font-smoothing: antialiased;
}
.txt-car-title{
    font-family: "VWHead", sans-serif;
    font-size: 31px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    -webkit-font-smoothing: antialiased;
}
.txt-car-subtitle{
    font-family: "VWHead", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    -webkit-font-smoothing: antialiased;
}
.txt-car .w-100{
margin-bottom: 1rem;
}
.car-footer{
    margin-top:25rem;
}
.car-contact{
    margin-top:15rem; 
}
.car-component{
    margin-top:-8rem; 
}
.car{
    margin-top:1.5rem;
}
.car-news{
    margin-top:11rem;
    margin-left:-1.5rem;
}
.car-component2{
    background: #000000;
}

.car-component2 .btn-Gallery {
    position: relative;
    left: -0.3rem;
   }
   /* .car-component2 .slick-dots {
    margin-left: -9.5vw;
  } */
  @media(max-width:1440px){
    .emobillity-component2  .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       /* .emobillity-component2 .slick-dots {
        margin-left: -8.5vw;
      } */
  }
  @media(max-width:1280px){
    .car-component2 .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       /* .car-component2 .slick-dots {
        margin-left: -5vw;
      } */
  }

  @media(max-width:1024px){
    .car-component2 .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       /* .car-component2 .slick-dots {
        margin-left: -3vw;
      } */
  }
  @media(max-width:992px){
    .car-component2 .btn-Gallery {
        position: relative;
        left: 0.5rem;
       }
       /* .car-component2 .slick-dots {
        margin-left: -0.7vw;
      } */
  }
  @media(max-width:960px){
    .car-component2 .btn-Gallery {
        position: relative;
        left: 1.1rem;
       }
       /* .car-component2 .slick-dots {
        margin-left: 2vw;
      } */
  }
  @media(max-width:768px){
    .car-component2 .btn-Gallery {
        position: relative;
        left: -0.2rem;
       }
       /* .car-component2 .slick-dots {
        margin-left: 2vw;
      } */
  }