.modal-container {
    background-color: rgba(0, 0, 0, 0.6);
    background-size: cover;
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    opacity: 1;
    
}
    .modal-container.parent-holder-configs {
        top:-3rem;
        left:-5rem;
    }
.modal-container .content-wrapper{
    width:25vw;
    min-height:24vh;
    background-color:#fff;
    border-radius: 1rem;
    flex-direction: column;
    display: flex;
    position: relative;
    left:50%;
    top:50%;
    margin-top:-12.5vh;
    margin-left:-12.5vw;
    padding-top: 0.4rem;
    z-index: 90;
    transition: 0.5s all;
}

.modal-container > .content-wrapper {
    width:35vw;
    min-height: 25.5vh;
    margin-top:-10vh;
    margin-left:-17.5vw;
}

.modal-container > .content-wrapper .content {
    height: 100%;
}

.modal-container h1 {
    font-size:2.2rem;
    font-weight: bold;
    margin: 1.5rem 0 1rem 0;
    text-align:center;
}


.modal-container h1 {
    font-family: 'VWHeadOffice-Bold'
}


.modal-container h2 {
    font-size:1.8rem;
    margin: 1rem 4rem 2.5rem 4rem;
    text-align:center;
    font-weight: 500;
    color: #666;
    line-height: 1.4;
    letter-spacing: normal;
}

.modal-container .content button {
    width:100%;
    height:60px;
    font-size: 1.9rem;
    font-weight: bold;
    text-transform: uppercase;
    border:none;
    border-top:1px solid rgba(138, 139, 144, 0.5);
    background:none;
    color:rgb(2,165,231);
    text-align: center;
}

.modal-container .decision-buttons button {
    display: inline-block;
    width: 50%;
    line-height: 1.6rem;
    padding: 0;
    text-transform: none;
    font-weight: 400;
    font-size: 1.2rem;
}

.modal-container .decision-buttons button:first-child {
    border-right:1px solid rgba(138, 139, 144, 0.5);
}

.styles_closeButton__20ID4,
.modal-container .btn-close {
    display:none;
}

.transformation-20{
    transform: translate(0,-35%) !important;
}



@media (max-width: 544px) {
    .modal-container > .content-wrapper {
        width:90vw;
        margin-left: -45vw;
    }
}