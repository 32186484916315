.shareButtons{
    border: none;
    padding: 10% 0;
}
.buttonContainer{
    top: 0;
    margin-top: 1rem;
}
.button-inside-col {
    display: inline-block;
    vertical-align: top;
}
.Download-icons{
    width:100%;
}

.buttonIcon > img{
    width: 100%;
}

@media(max-width: 576px){
    
    .newsButtons{
        padding-right:6px;
    }

}