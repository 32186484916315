.vw-page {
	display: flex;
	display: -webkit-flex; /* Safari */
	position: relative;
	width: 100vw;
	height:100vh;
}	

_:-ms-fullscreen, :root .vw-body {
	min-width: 960px;
	margin:0 auto;
}

_:-ms-fullscreen, :root .vw-side-menu + .row {
	min-width: 100vw;
}

.vw-background-gray {
	background-color: #f7f7f7;
	margin-top: -2.6px;
	height: 30%;
}

@media (max-width: 992px) {
	body {
		/* overflow-x: hidden; */
	}
	.vw-page {
		overflow: hidden;
		height: auto;
	}
}


@media (max-width: 1600px) {
	.vw-page {
		width: 100%;
		
	}

}

@media (max-width: 1024px) {
	.vw-page {
		flex-direction: column;
		width: 100%;
	}
	.vw-background-gray {
		background-color: #f7f7f7;
		height: 85%;
	}
	
}
@media (min-width: 992px) {
	.vw-body {
		padding-left: 103px;
		
	}
	/* .vw-background-gray {
		max-width: 100vw;
	} */
}
@media(max-width:960px){
	.media-gallery-container{
		margin-left: -100px !important;
	}
}

@media (max-width: 544px) {
	.vw-background-gray {
		margin-left: 0rem !important;
		padding-right: 0 !important;
		height: 100%;
	}
	
}

.vw-italic {
	font-style: italic;
}

.vw-font-12px {
	font-size: 1.2rem;
}

.vw-font-20px {
	font-size: 2.0rem;
}

.vw-no-padding {
	padding: 0px !important;
}

.vw-background-black{
	background-color: #f7f7f7;
	top:-0.3rem;
	width: 100%;
}

