/* .vw-background-gray {
    margin-left: 5rem;
 } */
 .vw-footer{
    background-color: #f7f7f7;
}

.vw-internal-car{
    margin-top: 3rem;
}
.bg-interno{
    background-color: #f3f3f3;
    margin-left: 0.1rem;
}
.bg-interno img{
   margin-top: 1rem;
   margin-bottom: 1.1rem;
}
.bg-interno .vw-page-subtitle{
    margin-bottom: -1rem;
}
.padding-component{
    padding: 0.5rem !important;
}
.vw-internal-car-Gallery .btn-Gallery {
    position: relative;
    left: -0.4rem;
   }
   .vw-internal-car-Gallery .slick-dots {
    margin-left: -9.5vw !important;
  }
  @media(max-width:1440px){
    .vw-internal-car-Gallery .btn-Gallery {
        position: relative;
        left: -0.3rem !important;
       }
       .vw-internal-car-Gallery .slick-dots {
        margin-left: -8.5vw !important;
      }
  }
@media(max-width:1280px){
    .vw-internal-car-Gallery .btn-Gallery {
        position: relative;
        left: -0.3rem !important;
       }
       .vw-internal-car-Gallery .slick-dots {
        margin-left: -5vw !important;
      }
  }

  @media(max-width:1024px){
    .vw-internal-car-Gallery .btn-Gallery {
        position: relative;
        left: -0.3rem !important;
       }
       .vw-internal-car-Gallery .slick-dots {
        margin-left: -3vw !important;
      }
  }
  @media(max-width:992px){
    .vw-internal-car-Gallery .btn-Gallery {
        position: relative;
        left: 0.5rem !important;
       }
       .vw-internal-car-Gallery .slick-dots {
        margin-left: -0.7vw !important;
      }
  }
  @media(max-width:960px){
    .vw-internal-car-Gallery .btn-Gallery {
        position: relative;
        left: 1.1rem !important;
       }
       .vw-internal-car-Gallery .slick-dots {
        margin-left: 2vw !important;
      }
  }
  @media(max-width:768px){
    .vw-internal-car-Gallery .btn-Gallery {
        position: relative;
        left: -0.2rem !important;
       }
       .vw-internal-car-Gallery .slick-dots {
        margin-left: 2vw !important;
      }
  }
