
.contact__container{
  text-align: center;
  margin: 21.5px 0 31.5px;
}

.contact__image > img{
  width: 7.5rem!important;
  height: 7.5rem;
  clip-path: circle();
  margin-bottom: 0.5rem;
}

.contact__name{
  font-family: VWHeadOffice;
  font-size: 1.9rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.contact__item{
  font-family: VWTextOffice;
  font-size:    1.5rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.contact__email{
  font-family: VWTextOffice;
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 7px 0px;
  color: #0099d7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact__email > img {
  width: 1.5em!important;

}
