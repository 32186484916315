.empresa-component2  .btn-Gallery {
    position: relative;
    left: -0.4rem;
   }
.empresa-component2  .slick-dots {
  margin-left: -8.5vw;
}

  @media(max-width:1440px){
    .empresa-component2  .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       .empresa-component2 .slick-dots {
        margin-left: -10vw;
      }
  }

@media(max-width:1280px){
    .empresa-component2  .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       .empresa-component2 .slick-dots {
        margin-left: -5vw;
      }
  }

  @media(max-width:1024px){
    .empresa-component2  .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       .empresa-component2  .slick-dots {
        margin-left: -3vw;
      }
  }
  @media(max-width:992px){
    .empresa-component2  .btn-Gallery {
        position: relative;
        left: 0.5rem;
       }
       .empresa-component2  .slick-dots {
        margin-left: -0.7vw;
      }
  }

  @media(min-width:768px) and (max-width:992px){
    .imageSubtitle{
      margin-top: 1rem; 
    }
  }

  @media(max-width:544px){
    .imageSubtitle{
      margin-top: 2rem; 
    }
  }

  @media(max-width:960px){
    .empresa-component2  .btn-Gallery {
        position: relative;
        left: 1.1rem;
       }
       .empresa-component2  .slick-dots {
        margin-left: 2vw;
      }
  }
  @media(max-width:768px){
    .empresa-component2  .btn-Gallery {
        position: relative;
        left: -0.2rem;
       }
       .empresa-component2  .slick-dots {
        margin-left: 2vw;
      }
  }