  .row.loan-data.pb-4 {
    cursor: pointer;
  }

  .btn-approvalBackoffice {
    background: #0199da;
    width: 210px;
    height: 4.2rem;
    line-height: 4rem;
    border-radius: 28px;
    text-align: center;
  }
  .btn-approvalBackoffice-txt{
      color: #fff;
      font-family:VWTextOffice, sans-serif;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
  }
  .vw-approvalB-form{
      margin-top: 3rem;
  }

  .loan-data {
    border-bottom: 1px solid #666;
  }

  .loan-data .loan-number-title {
    font-size: 1.8rem;
    font-weight: bold;
    color:#0199da;
  }

  .loan-data .loan-number {
    color: #000;
  }

  .loan-data .loan-date {
    font-weight: bold;
  }

  .loan-data .loan-user-title {
    font-size: 1.4rem;
    color: #666;
  }

  .loan-data .loan-user {
    color: #000;
  }

  .loan-data .btn-go-approval {
    color:#0199da !important;
    font-weight: bold;
  }

  .approval-tier {
      width: 80%;
      height: 90%;
      min-height: 35px;
      margin: 0 auto;
      border-radius: 5px;
      border: solid 2px #0199da;
      background-color: #ffffff;
      font-size: 1.8rem;
      font-weight: bold;
      color:#0199da;
      line-height: 45px;
  }


  @media (max-width: 544px) {
    .loan-data .btn-go-approval,
    .loan-data .btn-go-approval + p {
      display: inline-block;
      vertical-align: middle;
      text-align: right
    }
    .loan-data .btn-go-approval + p {
      max-width: 32px;
      
      padding-top:0;
    }
  }