.CarouselGallery {
  width: 100vw;
  position: relative;
}

.gallery-container {
  width: 100%;
  background: #f2f2f2;
  padding-bottom: 3.5rem;
}
.whiteBG.gallery-container {
  background: #fff;
}
.CarouselGallery {
  width: calc(100vw - 8.7rem);
  padding-left: 1.5rem;
  overflow: hidden;
  
}

.container:not(.container-fluid) .CarouselGallery {
  padding: 0 0 2rem 0;
}
.container-fluid .CarouselGallery {
  padding-left: 5rem;
}
.container-fluid .CarouselGallery .slick-dots  {
  width:calc(100% - 2.5rem);
  }
.whiteBG .CarouselGallery .page-title{
  color: #000 !important;
}

 .CarouselGallery .slick-slide > div {
   display: flex;
   margin: 0 2rem 3rem 0;
 } 

 .container:not(.container-fluid) .CarouselGallery .col-md-11{
    max-width: 30%;
  }
  .container:not(.container-fluid) .CarouselGallery {
    width: 100%;
  }

  .container:not(.container-fluid) .CarouselGallery .slick-slider {
    margin-left: 0;
  }
  .container:not(.container-fluid) .CarouselGallery .slick-list  {
    width:calc(100% + 40px);
    overflow: hidden;
  }
  
  .container:not(.container-fluid) .CarouselGallery-title {
    margin-left:0
  }

  /* GALLERY FULL WIDTH */
  .gallery-full {
    width: 100vw;
    position: relative;
    margin-left: calc(-50vw - 44px);
    left: 50%;
    background: #f2f2f2;
  }
  .gallery-full.white-bg {
    background: #fff;
  }
  .gallery-full .container {
    padding-left: 103px;
  }
  .container:not(.container-fluid) .gallery-full .CarouselGallery {
    width: calc(100%);
    overflow: visible;
  }
  .container:not(.container-fluid) .gallery-full .slick-slider, 
  .container:not(.container-fluid) .gallery-full .slick-list {
    width: 100% 
  }
  .container:not(.container-fluid) .gallery-full .slick-slider .slick-slide > div {
    width: 34.5%;
  }
  .container:not(.container-fluid) .gallery-full .CarouselGallery .slick-list  {
    width:calc(100%);
    overflow: hidden;
  }
  .container:not(.container-fluid) .gallery-full .CarouselGallery .slick-prev {
   left: -3rem
  }
  .container:not(.container-fluid) .gallery-full .CarouselGallery .slick-next {
    right: -30px
   }
  .container:not(.container-fluid) .gallery-full .CarouselGallery-title {
    margin-left:3.8rem
  }

.CarouselGallery  .slick-dots li button{
  color: rgba(0,0,0,0);
  background: #0199da;
}
.whiteBG .CarouselGallery  .slick-dots li button{
  background: #0199da;
}
.whiteBG .CarouselGallery  .slick-dots li button{
  background: #0199da;
}

.CarouselGallery .slick-slider .slick-list > img,
.CarouselGallery .slick-slider .slick-list video {
    width: 100% !important;
    height:100%;
    max-height: 250px;
    min-height: 185px;
    margin-left: 0rem;
    border-radius: 9px;
    object-fit: cover;
}

.CarouselGallery .page-title{
    color: #000;
}

.CarouselGallery-subtitle{
    font-family:'VWHead', sans-serif;
    font-size: 16px;
    color: #0099d7;
    margin-top:-4.5rem;
    left:70.5rem;
    position: relative; 
}

.CarouselGallery .slick-slide > div {
  padding-right:18.5px;
}
.CarouselGallery .slick-slide > div > div {
  margin-left:3%;
  margin-bottom: 1rem;
}
.CarouselGallery .slick-slide > div > div:first-child {
  margin-left:0;
}

@media(min-width:1679px){
  .CarouselGallery .slick-slide > div > div:first-child {
    margin-left:10px;
  }
}
@media (min-width: 992px) and (max-width: 1200px){
  .CarouselGallery .slick-slide > div {
    max-width: 29.6vw;
  }
  .container:not(.container-fluid) .CarouselGallery .slick-slide >div {
    width: 32%;
  }
.CarouselGallery .btn-Gallery {
  position: relative;
  left: -3rem;
 }
}

@media (min-width: 1200px){
  .CarouselGallery .slick-slide > div {
    margin-right: 0.5rem;
    max-width: 30vw;
  }
  .container:not(.container-fluid) .CarouselGallery .slick-slide >div {
    width: 32.6%;
  }
  .CarouselGallery .btn-Gallery {
    position: relative;
    left: -2.2vw;
   }
}

@media (min-width: 768px) and (max-width: 992px){
  .container-fluid .CarouselGallery {
    width: calc(100vw - 1rem);
  }
  .CarouselGallery .slick-slide > div {
    margin-right: 0.5rem;
    max-width: 32.5vw;
  } 
  .container:not(.container-fluid) .CarouselGallery .slick-slide >div {
    max-width: 32.5%
  }
  .CarouselGallery .btn-Gallery {
    position: relative;
    left: 2rem;
   }
   .container-fluid .CarouselGallery .slick-prev {
    left: -3rem
   }
   .homeMobile .CarouselGallery .holder-video .small-image > video, 
   .homeMobile .CarouselGallery .holder-video .small-image > iframe, 
   .homeMobile .CarouselGallery .holder-image .small-image > img {
    min-height: 190px;
   }
   /* Gallery FULL */
   .container:not(.container-fluid) .gallery-full {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  }
  .container:not(.container-fluid) .gallery-full .container {
    padding-left: 15px;
  }
  .container:not(.container-fluid) .gallery-full .slick-slider .slick-slide >div {
    max-width: 36%;
    width: 35.3%;
    overflow: hidden;
  }
  .container:not(.container-fluid) .gallery-full .CarouselGallery-title {
    margin-left:0rem
  }
}

@media (min-width: 544px) and (max-width: 768px){
  .CarouselGallery .col-md-11 {
    max-width:40vw;
  }
  .container-fluid .CarouselGallery {
    width: calc(100vw - 1.5rem);
    padding-left: 3.5rem;
  }
  .CarouselGallery .slick-slide > div {
    margin-right: 0;
    max-width: 100vw;
    padding-right: 0.5rem
  }
  .container:not(.container-fluid) .CarouselGallery .slick-slide >div {
    max-width: 99%;
  }
  .homeMobile .CarouselGallery .slick-slide > div {
    margin-right: 0;
    max-width: 100vw;
    padding-right: 0rem;
    margin-bottom: 1.5rem;
  }
  .homeMobile .CarouselGallery .slick-slide > div > div:first-child {
		overflow: hidden;
		border-radius: 9px;
		padding: 0;
    margin: 0 0 0 15px;
    flex: 0 0 calc(50% - 3rem);
    max-width: calc(50% - 3rem);
  }
  
  .homeMobile .CarouselGallery .slick-slide > div > div {
    margin-left:1rem;
  }
  .CarouselGallery .btn-Gallery {
    position: relative;
    left: 0vw;
   }
   .container-fluid .CarouselGallery .slick-list {
      overflow: hidden;
    }
   .container-fluid .CarouselGallery .slick-prev {
    left: -1rem
   }
   /* Gallery FULL */
   .container:not(.container-fluid) .gallery-full {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  }
  .container:not(.container-fluid) .gallery-full .container {
    padding-left: 15px;
  }
  .container:not(.container-fluid) .gallery-full .slick-slider .slick-slide >div {
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }
  .container:not(.container-fluid) .gallery-full .CarouselGallery-title {
    margin-left:0rem
  }
  .container:not(.container-fluid) .gallery-full .slick-dots {
    width: 100%;
  }
}

/* Phone 6, 7, 8, X */
@media (max-width: 543px) {
  .CarouselGallery .col-md-11 {
    max-width:80vw;
  }
  .container-fluid .CarouselGallery {
    width: calc(100vw - 2rem);
    padding-left: 4rem;
  }
  .CarouselGallery .slick-slide > div {
    max-width: 100%;
    /* max-height: 26vh; */
    margin-bottom: 2rem;
    margin-right: 0;
    max-width: 100vw;
    padding-right: 0.5rem;

  }
  .homeMobile .CarouselGallery .slick-slide > div {
    padding-right: 3rem;
  }
  .CarouselGallery .slick-slide > div  > div,
  .CarouselGallery .slick-slider .slick-list > img,
  .CarouselGallery .slick-slider .slick-list video {
    min-height: 185px;
  }
  .container-fluid .CarouselGallery .slick-list {
    overflow: hidden;
  }
  .container:not(.container-fluid) .CarouselGallery .slick-slide >div {
    max-width: 90%;
  }
  .CarouselGallery .btn-Gallery {
    position: relative;
    left: 5vw;
   }
   .container-fluid .CarouselGallery .slick-prev {
    left: -1rem
   }

   /* Gallery FULL */
   .container:not(.container-fluid) .gallery-full {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  }
  .container:not(.container-fluid) .gallery-full .container {
    padding: 0 30px;
  }
  .container:not(.container-fluid) .gallery-full .slick-slider .slick-slide >div {
    max-width: 100%;
    width: 100%;
  }
  .container:not(.container-fluid) .gallery-full .slick-slider .slick-slide >div >.col-md-12 {
    border-radius: 5px;
    overflow: hidden;
    padding: 0;
    margin: 0 15px;
  }
  .container:not(.container-fluid) .gallery-full .CarouselGallery .slick-next {
    right: -23px
   }
  .container:not(.container-fluid) .gallery-full .CarouselGallery .slick-prev {
    left: -2.2rem
   }
  .container:not(.container-fluid) .gallery-full .CarouselGallery-title {
    margin-left:0rem
  }
  .container:not(.container-fluid) .gallery-full .slick-dots {
    width: 100%;
  }
}