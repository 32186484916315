.emobillity-component2 .btn-Gallery {
    position: relative;
    left: -0.4rem;
   }
.emobillity-Buttons{
    position: absolute;
}
   /* .emobillity-component2 .slick-dots {
    margin-left: -9vw ;
  } */
  @media(max-width:1440px){
    .emobillity-component2  .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       /* .emobillity-component2 .slick-dots {
        margin-left: -9.5vw !important;
      } */
  }
@media(max-width:1280px){
    .emobillity-component2 .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       /* .emobillity-component2 .slick-dots {
        margin-left: -5vw;
      } */
  }

  @media(max-width:1024px){
    .emobillity-component2 .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       /* .emobillity-component2 .slick-dots {
        margin-left: -3vw;
      } */
  }
  @media(max-width:992px){
    .emobillity-component2 .btn-Gallery {
        position: relative;
        left: 0.5rem;
       }
       /* .emobillity-component2 .slick-dots {
        margin-left: -0.7vw;
      } */
  }
  @media(max-width:960px){
    .emobillity-component2 .btn-Gallery {
        position: relative;
        left: 1.1rem;
       }
       /* .emobillity-component2 .slick-dots {
        margin-left: 2vw;
      } */
  }
  @media(max-width:768px){
    .emobillity-component2 .btn-Gallery {
        position: relative;
        left: -0.2rem;
       }
       /* .emobillity-component2 .slick-dots {
        margin-left: 2vw;
      } */
  }