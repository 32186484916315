  .loanRequestStep2 .car-inside {
    max-width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
  .loanRequestStep2 .car-inside .slick-slider {
    max-width: 100%;  
  }
  .loanRequestStep2 .page-title {
    font-size: 2rem
  }
  .loanRequestStep2 .card .card-value .card-txt-value {
    color: #000000
  }

  .loanRequestStep2 .car-container {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f2f2f2;
  }
.loanRequestStep2 .car-container .page-title{
  font-size: 19.5px;
  margin-left: -20px;
  padding-left: 25px !important;
  height: auto;
  z-index: 999;
}

.loanRequestStep2 .vw-car .row img {
  margin-top: .5rem;
}

.loanRequestStep2 .vw-car .row:last-child {
  margin-bottom:0;
}

.loanRequestStep2 .slick-list {
  max-width: 97%;  
  margin-left: .5rem;
  overflow: hidden;
}
.loanRequestStep2 .vw-car .slick-arrow {
  top: 50%;
}

.loanRequestStep2 .car-inside .slick-arrow.slick-prev {
  left: 0;
}
.loanRequestStep2 .car-inside .slick-arrow.slick-next {
  right: -1rem;
}


@media (max-width: 544px) {
  .loanRequestStep2 .vw-car .slick-dots {
    margin-left: 2.1rem;
    margin-top: 0;
  }
  .loanRequestStep2 .vw-car .slick-arrow {
    top: 45%;
  }

  .loanRequestStep2 .car-inside .slick-arrow.slick-prev {
    left: -1rem;
  }
  .loanRequestStep2 .car-inside .slick-arrow.slick-next {
    right: -1.6rem;
  }
}