.vw-header-icons {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    z-index: 100;
    position: relative;
}

.vw-header-logo {
    display: none;
}

.vw-display-none {
    display: none !important;
}

.vw-display-block {
    display: block !important;
}

.vw-header-icons-item.loggedin-button {
    background-color: #000;
    border-radius: 50%;
    padding-left: 14px;
    padding-right: 14px;
    margin-top: -3px;
    margin-right: 7px;
    height:46px;
    max-width: 46px;
}

.vw-header-icons-item.loggedin-button #headerLoggedButtonContainer{
    margin-right: 0;
    width:auto !important;
}

/* Login */
.login-icon {
    background: url('./img/login-icon.svg') top left no-repeat;
    background-size: 100%;
    width: 18px;
    height: 30px;
    margin-top: 21px;
}
.login-icon-hover {
    background: url('./img/login-hover-icon.svg') top left no-repeat;
    background-size: 100%;
    width: 18px;
    height: 30px;
    margin-top: 0;
}
@media(max-width:992px){
    .login-icon {
        margin-top: 9px;
    }
}

/* Search */

.vw-header-search {
    right: 98px;
    position: absolute;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    display: none;
    -webkit-transition: opacity ease-out 0.21s;
    transition: opacity ease-out 0.21s;
}

.vw-header-search.is-visible {
    display: flex;
    opacity: 1;
    pointer-events: auto;
    z-index: 1030 !important
}

.vw-header-search-field {
    width: 440px;
}

.vw-header-search-input {
    width: 100%;
    height: 46px;
    line-height: 46px;
    padding-left: 40px;
    padding-right: 40px;
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    background-color: white;
    border: none;
    color: black;
}

.vw-header-search-icon {
    position: absolute;
    top: 4px;
    right: 11px;
}

.vw-header-search-icon button{
    border: none;
    margin: .5rem 0 0 0;
}
@media (min-width: 992px) {
    .btn-header-login:hover {
        background-color:#0098da;
    }
    .btn-header-login:hover .brand-icon{
        background: url('./img/login-hover-icon.svg') top left no-repeat;
        background-size: 100%;
    }
}
/**
    CART
*/
.cart-has-item {
    width: 8px;
    height: 8px;
    background-color: #fff;
    position: absolute;
    top: 5px;
    right: -1rem;
    border-radius: 50%;
    border:1px solid #0098da;
}

@media (min-width: 992px) {
    .vw-header {
        position: absolute;
        top: 0;
        left: 100px;
        right: 0;
        padding: 38px 42px 0;
        z-index: 99;
    }
    .vw-header-icons {
        padding: 0;
        margin: 0;
    }
    .vw-header-icons-item {
        position: relative;
        display: flex;
        -webkit-box-align: center;
        justify-content: center;
        margin-left: 10px;
    }
    .vw-header-icons-item:nth-child(5) {
        display: none;
    }
    .vw-header-icons-item:nth-last-child(5) {
        display: none;
    }
    .vw-header-icons-item:last-child{
        display: none;
    }
    .vw-header-icons-item label {
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 2px rgba(29, 31, 32, 0.15);
        position: relative;
        z-index: 1;
        width: 46px;
        height: 46px;
        line-height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .vw-header-icons-item label {
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 2px rgba(29, 31, 32, 0.15);
        position: relative;
        z-index: 1;
        width: 46px;
        height: 46px;
        line-height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .vw-header-icons-item label {
        border-radius: 50%;
        background-color: #fff;
    }
    .vw-header-icons-item.loggedin-button .btn-header-login {
        background-color:transparent;
        margin-top: -.65rem;
        margin-left: .2rem;
    }
    .vw-header-icons-item-fav label {
        box-shadow: 0 0 0 rgba(29, 31, 32, 0.15);
    }

    .vw-header .btn-header-search:hover {
        background-color: #0098da;
    }
    .vw-header .btn-header-search:hover a{
        color:#fff
    }
    .login-txt{
        margin-top:1rem;
        
    }
    .login-txt:hover{
        color: #0199da;
    }
    .login-txt:click{
        color: #0199da;
    }
}

@media (max-width: 992px) {
    .vw-header {
        width: 100%;
        background-color: white;
        height: 80px;
        min-height: 80px;
        border-bottom: 1px solid #DEE1E3;
        display: flex;
        z-index: 30;
    }
    .vw-header-icons {
        padding-top: 12px;
        width: 100%;
    }
    .vw-header-icons-item {
        /* border-right: 1px solid #DEE1E3; */
        padding-left: 30px;
        padding-right: 30px;
    }
    .vw-header-icons-item:nth-child(3) {
        border-right: 0px;
    }
    .vw-header-icons-item-fav > #headerCartButtonContainer > div {
        box-shadow: none;
    }
    .vw-header-logo {
        display: block;
        padding-top: 8px;
    }
    .vw-header-search {
        /* right: 192px; */
        top: 8px;
    }
    .color-gray {
        background-color: gray;
    }
    .login-txt{
        margin-top:1rem;
    }
}

@media (max-width: 768px) {
    .vw-header {
        height: 70px;
        min-height: 70px;
    }
    .vw-header-search {
        right: 15px;
        top: 8px;
        left: 5px;
    }
    .vw-header-search-field {
        width: 100%;
    }
    .vw-header-icons-item {
        padding-left: 20px;
        padding-right: 20px;
    }
    .login-txt{
        margin-top:1rem;
    }
}

.overlay {
    position: fixed;
    /* Sit on top of the page content */
    display: block;
    /* Hidden by default */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 100;
    /* Specify a stack order in case you're using a different order for other elements */
}
@media(max-width:544px){
    .vw-header {
        z-index: 1000;
    }
    .login-txt{
        font-weight: bold;
    }
}