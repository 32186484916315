
.media-center-gallery{
    /* margin-top:1.9rem; */
    background-color: #f7f7f7;
    /* margin-left: 10rem; */
    /* left: 4.8rem; */

}

.media-center-gallery-1{
    margin-top:4rem;
    background-color: #f7f7f7;
    /* margin-left: 10rem; */
    /* left: 4.8rem; */

}
.media-center-gallery .gallery-component{
    margin-top:-4rem; 
    /* margin-left: 15rem; */
}
.media-center-gallery .page-title{ 
    color:#000;
}

.media-center-gallery .vw-page-subtitle{
    color:#000;
}
.media-center-gallery  .vw-breadcrumb {
    /* color:#fff;    */
    -webkit-font-smoothing: antialiased;
}

.speak-media{
    /* margin-left:6rem; */
}
.vw-gallery-center .page-title{
    margin-left:-1rem;
}
@media(min-width: 1679px){
    .vw-gallery-center .page-title{
        margin-left:-3rem;
    }
}

@media(max-width:992px){
    .media-center-gallery-1{
        margin-top:7.8rem;
    }
    .speak-media{
        margin-left:0;
    }
    .gallery-component{
        margin-left:0;
    }
    .media-center-gallery{
        margin-left:0;
    }
}
@media(max-width:960px){
    .media-center-gallery-1{
        margin-top:6.7rem;
    }
}
@media(max-width:768px){
    .media-center-gallery-1{
        margin-top:5.8rem;
    }
    .speak-media .page-title{
        margin-left:-2.5rem;
    }
}
@media(max-width:544px){
    .media-center-gallery-1{
        margin-top:8.2rem;
    }
    .speak-media .page-title{
        margin-left:0;
    }
}
/* @media(max-width:1024px){
    .media-center-gallery{
        margin-left: 0rem;
        margin-top:3rem;
        left: 12.8rem;
        margin-top:0rem;
    }
    .media-center-gallery .gallery-component{
        margin-left: 13rem;
    }
}
@media(max-width:992px){
    .media-center-gallery{
        left: 10.5rem !important;
        margin-top:3rem;
    }
    .media-center-gallery .gallery-component{
        left: -2.5rem;
    }
}
@media(max-width:544px){
    .media-center-gallery{
        margin-top:4.5rem;
    }
}
@media(max-width:320px){
    .media-center-gallery{
        margin-top:4.5rem;
    }
    .media-center-gallery .gallery-component{
        left: -2.5rem;
    }

} */

/* @media(max-width:375px){
.media-center-gallery{
    margin-left:10rem !important;

}

} */

.vw-gallery-center .vw-breadcrumb {
    -webkit-font-smoothing: antialiased;
}
.vw-gallery-center .car-margin{
    margin-top:7rem;
}
.vw-gallery-center .row{
    width: 138%;
}

.vw-gallery-center .holder-image,
.vw-gallery-center .holder-video  {
    margin-top: 4rem;
    padding-right: 1rem;
}

.vw-gallery-center .row .small-image > img {
    /* margin-top: 2.5rem; */
    min-height: 180px
}

.vw-gallery-center .page-title{
    font-size: 2.7rem;
    color: #000;
}

.vw-gallery-center .btn-media-gallery{
    background: #0199da;
    width: 190px;
    height: 4.2rem;
    line-height: 4rem;
    border-radius: 28px;
    margin:2rem auto;
    text-align: center;
  }
  .vw-gallery-center .btn-media-gallery-txt{
  font-family:VWTextOffice, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 3px;
  margin-left: 0rem;
  color: #fff;
  background: #0199da;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: top;
  }


@media(max-width:1024px){
    .vw-gallery-center .row{
        width: 140%;
    }
}
@media(max-width:960px){
    .vw-gallery-center .row{
        width: 141% ;
    }

    .vw-gallery-center .holder-image,
    .vw-gallery-center .short-image,
    .vw-gallery-center .short-image .cropped-image--sizer,
    .vw-gallery-center .row .small-image > img,
    .vw-gallery-center .row .small-image > video {
        /* margin-top: 2.5rem; */
        min-height: 200px
    }
}
@media(max-width:544px){
    .vw-gallery-center .row{
        width: 150% ;
    }
    .buttonIcon {
        padding: 0
    }
    .buttonIcon > img {
        width: 100%;
        width: 43px;
    }
    .img-download:hover{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        background-size: 75% !important;  
    }
    .img-download:active{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        background-size: 75% !important;  
    }
    .img-download{
        background: url('./img/bt-download-normal.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        background-size: 75% !important;  
    }
}