table, th, td {
    border: 1px solid black;
}

table{
    width:  100% !important;
    text-align: center;
}
thead{
    background-color: #fce19d;
}

ul{
    list-style-type: none;
    padding-left: 10px;
}
ol{
    padding-left: 10px;
}
li{
    margin-top:5px;
}