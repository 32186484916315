.newsletterFormRow__container{
  margin: 0px 0px 16px;
}

.newsletterFormCheckbox__container{
  margin: 0px 0px 24px;
}
.newsletterFormCheckbox__container .alert-success {
  max-width: 97%;
  display: none;
}
.newsletterFormCheckbox__container .alert-success.show {
  display: block;
}
.newsletterFormRow__title{
  margin: 22.5px 0px;
  font-family: VWHeadOffice;
  font-size: 1.5rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}

.newsletterForm > input, .newsletterForm > select{
  padding: 0.875rem 1.25rem 1rem;
  height: 4rem;
  margin-left: -1.2rem;
  margin-top:0.8rem;
}

.form-check-input {
  transform: translate(50%);
  left: 4.16%;
  margin-left: 0rem; 
}
.form-control{
  font-size: 1.2rem;
}

.input-error {
  border: 1px solid #f00 !important
}
.input-error-msg {
  position: absolute;
  font-size: 1.1rem;
  color:#f00
}
.vw-newsletter-form .vw-text-body{
  -webkit-font-smoothing: antialiased;
}

@media (min-width: 320px) and (max-width: 1920px) {
  .form-check-input {
    left: 5.33333%;
    top: 50%;
    margin-top: -4px;
  }
}

.newsletterForm__submitButtonContainer{
  padding: 0px;
}

@media (min-width: 992px){
  .newsletterForm__submitButtonContainer{
    padding: 0px 4.16%;
  }
}

.form-check-label{
  padding-left: 0;
  padding-right: 0;
}

.vw-newsletter-form .btn-newsletterForm{
  background: #0199da;
  width: 210px;
  height: 4.2rem;
  line-height: 4rem;
  border-radius: 28px;
  /* margin:2rem auto; */
  text-align: center;
}
.vw-newsletter-form .btn-newsletterForm-txt{
font-family:VWTextOffice, sans-serif;
font-size: 1.5rem;
font-weight: 600;
margin-top: 3px;
margin-left: 0rem;
color: #fff;
background: #0199da;
-webkit-font-smoothing: antialiased;
display: inline-block;
vertical-align: top;
}
@media(max-width: 540px){
  .vw-newsletter-form .btn-newsletterForm{
    margin:2rem auto;
  }
  .newsletterFormCheckbox__container .form-check-input {
    top:0;
    margin-top:10px;
    left: 2.33333%;
  }
}