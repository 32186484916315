.vw-tecnologi-Gallery .btn-Gallery {
    position: relative;
    left: -0.4rem;
   }
   .vw-tecnologi-Gallery .slick-dots {
    margin-left: -8.5vw ;
  }
  @media(max-width:1440px){
    .vw-tecnologi-Gallery .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       .vw-tecnologi-Gallery .slick-dots {
        margin-left: -9.5vw;
      }
  }
@media(max-width:1280px){
    .vw-tecnologi-Gallery .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       .vw-tecnologi-Gallery .slick-dots {
        margin-left: -5vw;
      }
  }

  @media(max-width:1024px){
    .vw-tecnologi-Gallery .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       .vw-tecnologi-Gallery .slick-dots {
        margin-left: -3vw;
      }
  }
  @media(max-width:992px){
    .vw-tecnologi-Gallery .btn-Gallery {
        position: relative;
        left: 0.5rem;
       }
       .vw-tecnologi-Gallery .slick-dots {
        margin-left: -0.7vw;
      }
  }
  @media(max-width:960px){
    .vw-tecnologi-Gallery .btn-Gallery {
        position: relative;
        left: 1.1rem;
       }
       .vw-tecnologi-Gallery .slick-dots {
        margin-left: 2vw;
      }
  }
  @media(max-width:768px){
    .vw-tecnologi-Gallery .btn-Gallery {
        position: relative;
        left: -0.2rem;
       }
       .vw-tecnologi-Gallery .slick-dots {
        margin-left: 2vw;
      }
  }