.Hatch-title{
    font-family: "VWHead", sans-serif;
    font-size: 30px;
    color: #9d9d9d;
    border-bottom-color: #d8d8d8;
    border-bottom-style: solid;
    border-width: 0.5px;
    -webkit-font-smoothing: antialiased;
    margin-top:10rem;
}
@media(max-width:544px){
    .Hatch-title{
        font-size: 24px;
    }
}