.vw-news-component.vw-text-body {
  font-family: VWTextOffice;
  font-size: 1.6rem;
  color: #666666;
  line-height: 1.5;
}

.vw-text-body {
  font-family: VWTextOffice;
  font-size: 1.6rem;
  color: #666666;
  line-height: 2;
}