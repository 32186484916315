.diplay-inline {
    display: inline-block;
    min-height: 168px;
    min-width: 150px;
}

.divSpan{
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 10px;
}

.imgBoard{
    width: 60px !important;
    height: 60px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.boardCard {
    border-radius: 2px;
    border: solid 1px #e8e8e8;
    background-color: #ffffff;
    text-align: center;
    word-wrap: break-word;
    min-height: 220px;
    height: 100%;
    width: 100%;
}
.margin-bottom-30{
    margin-bottom: 30px;
}
.titleBoard {
    font-family: 'VWHeadOffice-Bold';
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.subTitleBoard {
    font-family: 'VWHead', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}