.card {
    background: #f2f2f2;
    border: none;
    padding: 1.5rem
}

.card-title {
    font-family: 'VWHead', sans-serif;
    font-size: 2.1rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #0199da;
    display: inline-block;
}

.divInputCheck{
  display: inline-block;
}
.disabled .divInputCheck {
  opacity: .8;
  
}

.disabled .card-title {
  color:#ccc;
}
.card .card-value {
    font-size: 1.3rem;
    color: #666666
}

.card .card-value .card-txt-value {
    font-family: 'VWHead', sans-serif;
    color: #000000;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}


.inputCheckForm {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border:  0.5px solid #666666;
    margin-right: 5px;
}

.disclaimer-textarea {
  font-size: .8rem;
  color:#666666
}
.divInputCheck .inputCheckForm {
  display:none; /* Esconde os inputs */
}

.divInputCheck .card-title {
  cursor: pointer;
  font-size: 1.7rem;
}
.disabled .divInputCheck .card-title:before{
  opacity: .3;
}
.divInputCheck .inputCheckForm + .card-title:before {
  border: 1px solid #666;
  background-color: #fff;
  content: "\00a0";
  display: inline-block;
  height: 20px;
  line-height: 17px;
  margin: 0 .25em 0 0;
  padding:2px;
  vertical-align: top;
  width: 20px;
  border-radius:3px;
}

.divInputCheck .inputCheckForm:checked + .card-title:before {
  background: #fff;
  color: #0199da;
  content: "\2714";
  text-align: center;
}

.divInputCheck .inputCheckForm:checked + .card-title:after {
  font-weight: bold;
}




 .TermsModal {

   max-height: 90vh;
   overflow: hidden;
   /* padding-right: 2.5rem; */
 }


 .TermsModal .content {

 max-height: 80vh;
 overflow: scroll;
 /* padding-right: 2.5rem; */
}

@media (max-width: 544px) {
  .vw-loanRequestStep3 .card-body {
    padding:.8rem 0
  }
  .divInputCheck + .card-title{
    font-size: 1.6rem;
  }  
}