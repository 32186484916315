
.vw-article-title {
  font-family: VWHeadOffice-Bold;
  font-size: 2.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;  
  padding-top: 40px;
  padding-bottom: 20px;
}