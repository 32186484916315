.cart-table {
    border:1px solid #DEE1E3;
    width:100%;
}
.empty-cart {
    height: 20vh;
    text-align: center;
}
.cart-table th {
    font-size: 1.1vw;
    line-height: 22px;
    padding: 28px 40px 30px;
    vertical-align: middle;
    font-family: "VWText", Verdana, "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #BDC3C6;
    color: #1D1F20;
    font-weight: bold;
    border: none;
    text-align: center;
}
.cart-table th td {
    font-size: 1rem !important;
}
.cart-table tr {
    border-bottom:1px solid #DEE1E3;
}
td.cart-col-object ,
td.cart-col-type,
td.cart-col-media,
td.cart-col-quality  {
    padding: 1rem 0 2rem 0;
    border:0 solid #DEE1E3;
    border-width: 0 1px 0 0;
    font-size: 1.2vw !important;
}

td.cart-col-object {
    padding-right: .7rem
}

td.cart-col-object:hover {
    color:#0099d7 !important;
    cursor: pointer;
}
.cart-table tr td:last-child {
    border-width: 0;
}
td.cart-col-type,
td.cart-col-media,
td.cart-col-quality {
    text-align: center;
}
.cart-col-object {
    width:27%;
    max-width:27vw;
}
.cart-col-object .row {
    margin-left: .5rem;
}
.cart-col-type,
.cart-col-media {
    width:26%;
    max-width:18vw;
}
.cart-col-quality {
    width:26%;
    max-width: 26vw;
}

.cart-table .form-check,
.objectDetails.openDetails .form-check {
    width:70%;
    margin:0 auto;
    text-align: left;
    padding-left: 3.5rem;
}
.cart-table .form-check-label,
.objectDetails.openDetails .form-check-label {
    margin-top: .5rem;
}
.cart-col-object .vw-date-style{
    padding: 0;
}

.cart-col-object .title {
  font-size: 1.6rem;
}

.cart-col-object .short-image {
    margin:.7rem 0 0 0rem;
}

.cart-col-delete {
    text-align: center
}
.cart-col-delete .cart-contents--footer-action {
    margin-left: 0 !important;
}

/** FOOTER */
.cart-contents--footer {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 5rem;
}
.cart-contents--footer-actions {
    padding: 28px 20px;
    background-color: #F3F5F6;
    border: 1px solid #DEE1E3;
    border-top-width: 0;
    color: #808890;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    white-space: nowrap;
}
    .cart-contents--footer-action:not(.is-navigating-back)  {
        margin-left: 2rem;
    }      
    .cart-contents--footer-actions-left {
        -webkit-box-flex: 1;
        flex: 1 0 auto;
    }
    .cart-contents--footer-actions-right {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
    }
.cart-contents--legend {
    margin-top: 2.2rem;
    color: #808890;
}

.cart-contents--legend-media-qualities {
    display: block;
    margin-bottom: 12.5px;
}

.cart-contents--legend .visible-xs {
    display: none !important;
}


.cart-contents--size .btn-primary {
    font-size: 1.5rem;
    line-height: 10px !important;
    padding-top: 3px !important;
}
.cart-contents--size .btn-primary span {
    vertical-align: top;
}
.objectDetails {
    background: #fff;
    width:100vw;
    height: 100vh;
    max-height: 100vh;
    position:fixed;
    top:0;
    right:100vw;
    z-index: 99;
}
.objectDetails.openDetails {
    right:0;
    padding: 5rem;
    z-index: 9999
}
.objectDetails.openDetails .row .border-bottom {
    border-bottom: 1px solid #DEE1E3;
    padding-bottom: 2rem;
}
.objectDetails.openDetails .form-check {
    margin-left: 0;
    padding-left: 5.5rem;
}
.objectDetails .details-title {
    font-size: 2rem;
    font-weight: bold;
}


/** CART PREVIEW */
#headerCartButtonContainer .popover {
    width:440px;
    max-width: 90vw;
    border-radius: 10px;
    padding-top:15px;
    padding-bottom:15px;
    left:auto !important;
    top: 56px !important;
    right: -14px;
    transform: translate3d(0, 0, 0) !important;
}

#headerCartButtonContainer .bs-popover-bottom .arrow{
    left:90% !important;
}

#headerCartButtonContainer .bs-popover-bottom .arrow:before {
    border-bottom-color:#0099d7 !important;
}

#headerCartButtonContainer .cart-preview .cart-contents--footer-action:not(.is-navigating-back) {
    margin-left: -.5rem !important;
    margin-right: 0 !important;
}
#headerCartButtonContainer .cart-preview .cart-contents--footer-action:not(.is-navigating-back) .brand-icon.is-1x {
    font-size: 20px;
    opacity: 0.5;
}

#headerCartButtonContainer .cart-preview .cart-contents--footer-action.is-navigating-back .brand-icon{
    margin-top: 3px;
}
#headerCartButtonContainer .cart-preview .row {
    padding:0 2rem;
}
#headerCartButtonContainer .cart-preview .row .col-preview:first-child {
    padding-left: 0;
    padding-right: 0;
}

#headerCartButtonContainer .cart-preview .row .col-preview {
   border-bottom: 1px solid #DEE1E3;
   padding-bottom: 2rem;
   margin-bottom: 2rem;
}

#headerCartButtonContainer .cart-preview .row .col-preview .newscomponenttitle .title{
    font-size: 1.5rem
 }

 #headerCartButtonContainer .cart-preview .empty-cart-text {
    font-size: 1.3rem
 }

 #headerCartButtonContainer .cart-preview .empty-cart-text {
    font-size: 1.3rem
 }
 

 #headerCartButtonContainer .cart-preview .is-more-objects {
    color: #BDC3C6
 }

 #headerCartButtonContainer .cart-preview .btn-primary {
     font-family: 'VWTextOffice';
    font-size: 1.5rem;
    line-height: 10px !important;
    font-weight: 400;
    padding-top: 5px !important;
 }
@media (max-width: 992px) {
    .cart-col-object > .row > .col-4,
    .cart-col-object > .row > .col-8  {
        padding-left: 5px;
        padding-right: 5px;
    }
    .cart-table th {
        font-size: 1.2vw;
    } 
    td.cart-col-object {
        width: 45%;
        max-width: 23vw;
    }
    .cart-table td.cart-col-type,
    .cart-table td.cart-col-media,
    .cart-table td.cart-col-quality{
        width: 20%;
        max-width: 8vw;
        font-size: 1.5vw !important
    }
    .cart-col-object .vw-date-style{
        font-size: 1rem;
    }
    .cart-col-object .title {
        font-size: 1.4rem;
        margin-right: .5rem;
    }
    .cart-table .form-check {
        padding-left: 2.2rem;
    }
    .cart-table .form-check .form-check-input {
        left:0;
    }
    .cart-table .cart-col-delete {
        width: 5%;
        max-width: 1vw;
    }
    .cart-table .cart-col-delete .cart-contents--footer-action .brand-icon.has-text{
        font-size: 18px;
    }
    
}

@media (max-width: 767px) {
    .cart-contents--legend {
        text-align: center
    }
    .cart-contents--legend .visible-xs {
        display: block !important;
    }
    .cart-content-mobile > .row {
        margin: 0;
    }
    .cart-content-mobile .title {
        font-size: 1.8rem;
    }
    .cart-content-mobile .vw-date-style {
        padding:0 0 .5rem 0
    }

    .cart-content-mobile .object-title {
        height: 60px;
        line-height: 60px;
        font-size: 2rem;
        /* background-color: #BDC3C6; */
        font-weight: bold;
        -webkit-font-smoothing: antialiased;
        padding: 0;
    }
    .cart-content-mobile .row-content {
        width: 100%;
        margin: 2rem 0;
        padding-bottom: 1rem;
        border-bottom: 1px solid #DEE1E3;
    }
    .cart-content-mobile .row-content .col-image{
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .cart-contents--footer-actions  {
        flex-direction: column-reverse;
        background: transparent;
        border-width: 0;
    }

    .cart-contents--size {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 2rem;
        font-size: 1.2rem;
    }
    .cart-contents--size .btn-primary {
        font-size: 1.5rem;
        line-height: 17px !important;
    }
}


@media (min-width: 544px) {
    .small-image .holder-buttons .cartButtonImage {
        top:.9rem;
        left: 0;
        right: 1rem;
    }

    .play-btn:hover + .short-image .small-image .holder-buttons .cartButtonImage,
    .play-btn + .short-image .small-image:hover .holder-buttons .cartButtonImage {
        top:.1rem;
    }
    .small-image .holder-buttons .cart-mark {
        z-index: 20;
        top: 2px;
        right: 3px;
    }
}

@media (max-width: 992px) {
    .small-image .holder-buttons .cart-mark {
        right: -15px;
    }
}
@media (max-width: 543px) {
    #headerCartButtonContainer .popover {
        top:50px !important;
        right: calc(100vw - 440px) !important;
    }
    #headerCartButtonContainer .bs-popover-bottom .arrow {
        left: auto !important;
        right: 70px;
    }
    #headerCartButtonContainer .cart-preview .row {
        padding:0 0 0 1.7rem;
    }
    .cart-content-mobile .row-content .col-image{
        flex: 0 0 32.666667%;
        max-width: 32.666667%;
        padding-right: 0px;
        padding-left: 0px;
    }
    .cart-content-mobile .title {
        font-size: 1.6rem;
    }
}