.confirmationModal{
  padding: 1rem;
}

.legalImage{
  padding-top: 20px;
}
.closeModal{
  position:absolute;
  font-size: 4rem;
  right: 1rem;
  top: 0rem;
  z-index: 5;
}
.button__container{
  padding-top: 1rem;
}
.agreeBox{
  padding: 1rem 0rem 0rem;
}

.agreeBox__input{
  position: absolute;
  width: 1.5em; 
  left: 15px;
  top: 0.4rem;
}

.agreeBox__confirmButton{
  background: #0099DA;
  border: #0099DA;
  margin-right: 0.2rem;
}

.agreeBox__confirmButton:hover{
  background: #066690;
}

.agreeBox__confirmButton:active{
  background: #0B4D71!important;
}

.agreeBox__cancelButton{
  margin-right: 0.2rem;
  border:none;
}

.btn-primary{
  margin: 0;
}

.download-cart .vw-page-subtitle {
  display: none;
}
.download-cart .form-group .form-control {
  margin-top: 1rem;
  height: 42px;
}

@media(min-width: 768px){
  .confirmationModal{
    padding: 5rem;
  }
}
@media(max-width: 768px){
  .confirmationModal .page-title {
    font-size: 2rem !important;
  }
  .confirmationModal .vw-page-subtitle,
  .confirmationModal .vw-text-body {
    font-size: 1.4rem !important;
  }
}
