.popOverButtons{
    border: none;
    padding: 10% 0;
    min-width: 32px;
    margin-top: -.5rem;
}


.popover{
    border: #0099d7 1px solid;
    margin-left:-1.2rem;
    width: 13rem;
    
}

.roundPopover.popover{
    border-radius: 50px;
    padding:15px;
    width: 90%;
    /* margin-left: -30rem; */
    max-width: 60px;
}
.loggedinPopover.popover {
    width: auto;
    margin-left:0;
    border: solid 1px #cfcfcf;
}
.loggedinPopover.popover .popover-inner {
    margin:0;
}
.loggedinPopover.popover .popover-inner .list-group-item {
    padding:0 !important;
    border-top: solid 1px #cfcfcf;
}
.loggedinPopover.popover .popover-inner .list-group-item:hover {
    background:none;
}
.loggedinPopover.popover .popover-inner .list-group-item a{
    display: flex;
    flex-direction: column;
    padding:20px 45px 10px 20px !important;
}
.loggedinPopover.popover .popover-inner .list-group-item a .icon {
    align-self: flex-end;
    top:-1.3rem;
    margin-right: -3.5rem;
}

.loggedinPopover.popover .popover-inner .list-group-item span {
    font-size: 1.5rem !important;
}

.bgdownload{
    left: 1.2rem !important;
    top: 0.5rem;
    position: relative;
    background-size: 70% !important; 
   }

.bs-popover-top .arrow::before {
    bottom: 0;
    border-top-color: #0099d7;
    
}

.buttonIcon > img{
    width: 90%; 
    max-width: 48px;
}

.buttonIcon.btn-expand{
    width: 42px;
    padding: 9.1px 9.2px;
    height: 42px;
    border-radius: 50%;
   background: #fff;
   color: #0099d7 !important;
   vertical-align: sub;
   border-color: #0099d7;
   border: solid 1.45px;
  
}
.buttonIcon.btn-expand:active{
    background: #0099d7;
    color: #fff !important;
    border-color: #0099d7;
}

.buttonIcon.btn-expand:hover{
    background: #0099d7;
    color: #fff !important;
    border-color: #0099d7;
}

.btdownload{
    background: url('./img/bt-download-normal.svg') !important;
    background-repeat: no-repeat !important;
    width:48px;
    height: 48px;
    border-radius: 27%;
   background: #0099d7;
   color: #fff !important;
   padding: 0;
    background-size: 90% !important; 
    margin-top: 0rem;
    margin-left: 0rem;
}

.btdownload:hover{
    background: url('./img/bt-download-hover.svg') !important;
    background-repeat: no-repeat !important;
    width:48px;
    height: 48px;
    border-radius: 27%;
   background: #0099d7;
   color: #fff !important;
   padding: 0;
    background-size: 90% !important;  
    margin-top: 0rem;
    margin-left: 0rem;
}
.btdownload:active{
    background: url('./img/bt-download-hover.svg') !important;
    background-repeat: no-repeat !important;
    width:48px;
    height: 48px;
    border-radius: 27%;
   background: #0099d7;
   color: #fff !important;
    background-size: 90% !important;  
    margin-top: 0rem;
    margin-left: 0rem;
}

.popOverButtons.list-group-item .btdownload {
    padding:0;
}

#expandImageDownloadContainer .btdownload {
    margin-top: 1rem;
}
.img-download{
    /* padding: 32.5px 29px 19px 28px; */
    background-size: 75% !important; 
    left: 0rem;
    background: url('./img/bt-download-hover.svg') !important;
    background-repeat: no-repeat !important;
    width:48px;
    height: 48px;
}
.img-download-bt{
    margin-top: 2rem;
    padding: 32.5px 29px 19px 28px;
    margin-top: 1rem;
}
.img-download-bt:hover{
     
    margin-top: 1rem;
}
.btn-compartilhar:hover{
    background: url('./img/bt-compartilhar-hover.svg') !important;
    background-repeat: no-repeat !important;
    width:48px;
    height: 48px;
    border-radius: 40%;
   background: #0099d7;
   color: #fff !important;
   /* padding: 10.5px 11px 10px 10px; */
   border: none !important;
    background-size: 90% !important;
}
.btn-compartilhar{
    background: url('./img/bt-compartilhar-normal.svg') !important;
    background-repeat: no-repeat !important;
    width:48px;
    height: 48px;
    border-radius: 40%;
   background: #0099d7;
   color: #fff !important;
   /* padding: 10.5px 11px 10px 10px; */
   border: none !important;
    background-size: 90% !important;
    margin-left: -1rem;
}

.buttonIcon.btn-expand .brand-icon.is-maximize:before{
    position: relative;
    top: 0.4rem;
    left: 0.1rem;
}

.holder-image-size-buttons .imageSize{
   margin:0 !important;
   padding:0 !important;
   max-height: 15px !important;
}
.holder-image-size-buttons > img{
    margin:0 !important;
 }
.imageSize{
    text-align: left;
    padding:4px 8px !important;
  }
  .holder-image .imageSize{
    padding:8px 15px !important;
  } 
  .holder-image .imageSize:hover {
      color: #fff;
  }
  .holder-image-size-buttons + div .list-group .imageSize:last-child {
    border-bottom:0;
  }
.imageSize:hover{
background: #efefef;
}

.imageSize:hover{
background: #efefef;
}
.imageSize__size{
font-family: VWHeadOffice;
color: #0199da !important;
font-weight: bold;
font-size: 1.2rem;
}

.imageSize:hover > .imageSize__size {
/* color: white !important; */
}

.imageSize__value {
font-family: VWTextOffice;
font-size: 0.875rem;
color: #666666;
line-height: 2;
}

.imageSize:hover > .imageSize__value,
.imageSize:hover > .imageSize__value {
/* color: white !important; */
}

.shareContainer img {
    width:43px;
}
/** CART BUTTON */
div[id*='cartButtonContainer'] {
    width: 48px;
    height: 48px;
    padding: 0;
}
 div[id*='cartButtonContainer'] div + div,
 div[id*='cartButtonContainer'] .popover{
    width:120px !important;
    max-width: 120px !important;
}

div[id*='cartButtonContainer'] > div {
    width: 100%;
    max-width: 48px;
}

div[id*='cartButtonContainer'] > div > div {
    width: 100%;
    height: 100%;
    max-width: 48px;
    position: relative;
}

div[id*='cartButtonContainer'] .popover-inner .list-group-item {
    padding:0 !important;
}
.small-image div[id*='cartButtonContainer'] .popover-inner .list-group-item{
    padding: 8px 15px !important;
}

.cartButtonImage {
    background: url('./img/bt-favorito-normal.svg') top left no-repeat;
    background-size: 100%;
    width: 100%;
    height: 100%;
}
.cartButtonImage:hover {
    background: url('./img/bt-favorito-hover.svg') top left no-repeat;
    background-size: 100%;
}

#headerCartButtonContainer {
    max-width: 46px;
    width: 46px;
    height: 46px !important;
    max-height: 46px;
    margin-top: 0;
    padding: 0
}
#headerCartButtonContainer > div {
    /* max-width: 46px;
    width: 46px;
    height: 46px !important;
    max-height: 46px; */
    box-shadow: 0 0 2px rgba(29, 31, 32, 0.15);
    border-radius: 50%;
    background-color: #fff;
}
#headerCartButtonContainer > div:hover {
    background-color: #0098da;
}
.vw-header .cartButtonImage {
    background: url('./img/bt-favorito-normal-header.svg') center center no-repeat;
    background-size: 78%;
    width: 46px;
    height: 46px;
    /* margin: -11px -10px 0 0; */

}
.vw-header .cartButtonImage:hover {
    background: url('./img/bt-favorito-hover-header.svg') center center no-repeat;
    background-size: 78%;
    width: 46px;
    height: 46px;
    /* margin: -11px -10px 0 0; */
}
.vw-header .cart-has-item {
    width: 9px;
    height: 9px;
    top: 10px;
    right: 12px;
    background-color: #0098da;
    border:1px solid #fff;
}
#headerCartButtonContainer > div:hover + .cart-has-item  {
    background-color: #fff !important;
    border:1px solid #0098da;
}
.small-image .holder-buttons #cartButtonContainer.buttonContainer{
    margin-left: -4rem;
    position: absolute;
    top: 1rem;
    width: 4.2rem;
    left: 12.5rem;
}
.small-image .holder-buttons div[id*='cartButtonContainer'].buttonContainer {
    margin-bottom: .9rem;
    margin-top:-.5rem;
}
.small-image .holder-buttons .cartButtonImage {
    background: url('./img/bt-favorito-normal.svg') top left no-repeat;
    width: 42px;
    height: 42px;
    background-size: 100%;
    position: absolute;
}

.CarouselGallery .small-image .holder-buttons .cartButtonImage{
    /* top: .9rem;
    left: 7rem; */
}

.small-image .holder-buttons .cartButtonImage:hover {
    background: url('./img/bt-favorito-hover.svg') top left no-repeat;
    width: 42px;
    height: 42px;
    background-size: 100%;
}

.cart-mark {
    color: #0099d7;
    position: absolute;
    top: -4px;
    right: -5px;
    text-align: center;
    font-size: 12px;
    min-width: 20px;
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-weight: bold;
    border-radius: 50%;
    background: #fff;
    border:1px solid #0099d7;
    z-index: 20;
}
.cart-mark::before {
    font-family: "VW Icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    margin-left: -3.5px;
    content: "";
    text-align: center;
}
.imageDownloadIcon {
    background-image: url('./img/bt-download-normal.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 2rem;
    width: 2rem;
    border: none;
    margin-right: 0.5rem; 
    outline: none!important;
    padding-right: 1rem;
}
.imageDownloadIcon:hover {
    background-image: url('./img/bt-download-hover.svg');
}
.imageDownloadIcon:click {
    background-image: url('./img/bt-download-hover.svg');
}
.popover{
    margin-left:-1.8rem;
}
.roundPopover.popover{
    border-radius: 50px;
    padding:15px;
    width: 100%;
    /* margin-left:-0.2rem; */
    margin-left:-1.3rem;
}
.bs-popover-top .arrow::before{
    margin-left:-0.3rem;
}
.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after{
    margin-left:-0.3rem;
}

#expandImageDownloadContainer .bs-popover-top,
.buttonContainer .bs-popover-top {
    min-width: 160px;
    width: 160px;
    margin-left: .5rem;
    margin-top:-10px;
  }
  .small-image .buttonContainer .bs-popover-top  {
    margin-top:0px;
  }
  .small-image .buttonContainer .bs-popover-top .popover-inner .list-group-item {
    padding: 5px 15px !important;
  }
#downloadBondary  .bs-popover-top,
#shareContainer .bs-popover-top,
#expandImageShareContainer .bs-popover-top  {
    min-width: 58px;
    width: 58px;
    padding: 1px;
    margin-left: 0rem;
}
#shareContainer .bs-popover-top {
    height: 270px;
    padding-top: 10px;
}
#downloadBondary  .popover-inner .list-group-item,
#shareContainer  .popover-inner .list-group-item, 
#expandImageShareContainer .popover-inner .list-group-item {
    border-radius: 50%;
    padding: 0 !important;
    margin: .3rem 0
}
#expandImageShareContainer .popover-inner .list-group-item {
    padding:.2rem !important;
}
#downloadBondary .bs-popover-top .arrow,
#shareContainer .bs-popover-top .arrow ,
#expandImageShareContainer .bs-popover-top .arrow{
    margin-left: .7rem;
}
  .buttonContainer .popover-inner {
    margin:.5rem;
  }

  #expandImageDownloadContainer  .popover-inner .list-group-item,
  .buttonContainer .popover-inner .list-group-item {
    border-width: 0;
    padding: 8px 15px !important;
  }

  #expandImageDownloadContainer .popover-inner .list-group-item:first-child,
  .buttonContainer .popover-inner .list-group-item:first-child {
    border-width: 0;
  }

  #expandImageDownloadContainer .popover-inner .list-group-item .imageSize__size,
  .buttonContainer .popover-inner .list-group-item .imageSize__size {
    font-size: 1.2rem;
    font-weight: normal;
  }
  #expandImageDownloadContainer .popover-inner .list-group-item .imageSize__value,
  .buttonContainer .popover-inner .list-group-item .imageSize__value {
    font-size: 1rem;
    color: #b5b5b5;
  }
  #expandImageDownloadContainer .popover-inner .list-group-item .imageDownloadIcon,
  .buttonContainer .popover-inner .list-group-item .imageDownloadIcon {
    height: 1.5rem;
    width: 1.5rem;
    padding: .1rem 0 0 1.7rem;
    background-size: 13px;
  }


  .newsButtons{ 
      max-height: 58px
  }
/* .popOverButtons.list-group-item .btdownload {
    margin-left: -.8rem !important;
} */

@media(max-width: 1193px){
    /* .btdownload:hover{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 75% !important;  
        margin-top: 0rem;
        margin-left: 0rem !important;
    }
    .btdownload:active{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 75% !important;  
        margin-top: 0rem;
        margin-left: 0rem !important;
    }
    .btdownload{
        background: url('./img/bt-download-normal.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 75% !important; 
        margin-top: 0rem;
        margin-left: 0rem !important;
        
    }
    
    .img-download{
        padding: 26.5px 29px 19px 28px;
        background-size: 75% !important;
        border-color: #0099d7;
        border: solid 1.9px; 
      
    }
    .img-download-bt{
        margin-top: 1rem;
    }
    .img-download-bt:hover{
        margin-top: 1rem;
    } */
    .roundPopover.popover{
        border-radius: 50px;
        padding:15px;
        width: 52px;
        margin-left:-0.7rem;
    }
    /* .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: 0.15rem;
        left: 0.15rem;
    } */
    

    
}

@media(max-width: 1920px){
    /* .btdownload:hover{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 80% !important;  
        margin-top: 0rem;
        margin-left:0rem !important;
    }
    .btdownload:active{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 80% !important;  
        margin-top: 0rem;
        margin-left: 0rem !important;
    }
    .btdownload{
        background: url('./img/bt-download-normal.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 80% !important; 
        margin-top: 0rem;
        margin-left: 0rem !important;
    }
    
    .img-download{
        padding: 26.5px 29px 19px 28px;
        background-size: 80% !important;
        border-color: #0099d7;
        border: solid 1.9px;  
        position: relative;
        left: -2rem;
        margin-top:1rem;
    }
    .img-download:hover{
        margin-top:1rem;
    }
    .img-download:active{
        margin-top:1rem;
    }
    .img-download-bt{
        margin-top: 1rem;
    }
    .img-download-bt:hover{
        margin-top: 1rem;
    } */
  
    /* .buttonIcon.btn-expand{
       margin-left: 2.5rem;
       padding: 12.5px 11px 9px 9px;
       position: relative;
       top: -0.1rem;
    }
    .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: 0.15rem;
        left: 0.15rem;
    } */
 
    .popover{
        margin-left:-1.8rem;
    }
    .roundPopover.popover{
        border-radius: 50px;
        padding:15px;
        width: 100%;
        /* margin-left:-0.2rem; */
        margin-left:-1.3rem;
    }
    .bs-popover-top .arrow::before{
        /* margin-left:-0.5rem; */
    }
    .bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after{
        /* margin-left:-0.5rem; */
    }
    .btn-compartilhar:hover{
        background: url('./img/bt-compartilhar-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 75% !important; 
        margin-top:1rem;
        margin-left: -0.8rem;
    }
    .btn-compartilhar{
        background: url('./img/bt-compartilhar-normal.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 75% !important; 
        margin-top:1rem;
        margin-left: -0.8rem;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    /* .bgdownload{
        left: 1.2rem !important;
        margin-left: -0.5rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
   
    
}
@media(max-width: 2000px){
    /* .bgdownload{
        left: 0.4rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
    /* .buttonIcon.btn-expand{
        margin-left: 2.5rem;
        padding: 10.5px -9px 8px 9px;
        position: absolute;
        margin-top:1rem;
        width:43px;
        height: 43px;
     }
     .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: -1.2rem;
        left: 0rem;
    } */
    /* .img-download{
        padding: 26.5px 29px 19px 28px;
        background-size: 75% !important;
        border-color: #0099d7;
        border: solid 1.9px;  
        position: relative;
        left: -2rem;
        margin-top:1rem;
    }
    .img-download:hover{
        background-size: 75% !important;
        margin-top:1rem;
    }
        
    .img-download:active{
        background-size: 75% !important;
        margin-top:1rem;
    } */
    .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
}
@media(max-width: 1900px){
    /* .bgdownload{
        left: 0.4rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
    /* .buttonIcon.btn-expand{
        margin-left: 2.5rem;
        padding: 10.5px -9px 8px 9px;
        position: absolute;
        margin-top:1rem;
        width:43px;
        height: 43px;
     }
     .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: -1.2rem;
        left: 0rem;
    } */
    /* .img-download{
        padding: 26.5px 29px 19px 28px;
        background-size: 75% !important;
        border-color: #0099d7;
        border: solid 1.9px;  
        position: relative;
        left: -2rem;
        margin-top:1rem;
    }
    .img-download:hover{
        background-size: 75% !important;
        margin-top:1rem;
    }
        
    .img-download:active{
        background-size: 75% !important;
        margin-top:1rem;
    } */
    .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
}
@media(max-width: 1800px){
    /* .bgdownload{
        left: 0.4rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
    /* .buttonIcon.btn-expand{
        margin-left: 2.5rem;
        padding: 10.5px -9px 8px 9px;
        position: absolute;
        margin-top:1rem;
        width:43px;
        height: 43px;
     }
     .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: -1.2rem;
        left: 0rem;
    } */
    /* .img-download{
        padding: 26.5px 29px 19px 28px;
        background-size: 75% !important;
        border-color: #0099d7;
        border: solid 1.9px;  
        position: relative;
        left: -2rem;
        margin-top:1rem;
    }
    .img-download:hover{
        background-size: 75% !important;
        margin-top:1rem;
    }
        
    .img-download:active{
        background-size: 75% !important;
        margin-top:1rem;
    } */
    .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
}
@media(max-width: 1700px){
    /* .bgdownload{
        left: 0.4rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
    /* .buttonIcon.btn-expand{
        margin-left: 2.5rem;
        padding: 10.5px -9px 8px 9px;
        position: absolute;
        margin-top:1rem;
        width:43px;
        height: 43px;
     }
     .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: -1.2rem;
        left: 0rem;
    } */
    /* .img-download{
        padding: 26.5px 29px 19px 28px;
        background-size: 75% !important;
        border-color: #0099d7;
        border: solid 1.9px;  
        position: relative;
        left: -2rem;
        margin-top:1rem;
    }
    .img-download:hover{
        background-size: 75% !important;
        margin-top:1rem;
    }
        
    .img-download:active{
        background-size: 75% !important;
        margin-top:1rem;
    } */
    .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
}
@media(max-width: 1600px){
    /* .bgdownload{
        left: 0.4rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
    /* .buttonIcon.btn-expand{
        margin-left: 2.5rem;
        padding: 10.5px -9px 8px 9px;
        position: absolute;
        margin-top:1rem;
        width:43px;
        height: 43px;
     }
     .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: -1.2rem;
        left: 0rem;
    } */
    /* .img-download{
        padding: 26.5px 29px 19px 28px;
        background-size: 75% !important;
        border-color: #0099d7;
        border: solid 1.9px;  
        position: relative;
        left: -2rem;
        margin-top:1rem;
    }
    .img-download:hover{
        background-size: 75% !important;
        margin-top:1rem;
    }
        
    .img-download:active{
        background-size: 75% !important;
        margin-top:1rem;
    } */
    .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
}
@media(max-width: 1500px){
    /* .bgdownload{
        left: 1.4rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
    /* .buttonIcon.btn-expand{
        margin-left: 2.5rem;
        padding: 10.5px -9px 8px 9px;
        position: absolute;
        margin-top:1rem;
        width:43px;
        height: 43px;
     }
     .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: -1.2rem;
        left: 0rem;
    } */
    /* .img-download{
        padding: 26.5px 29px 19px 28px;
        background-size: 75% !important;
        border-color: #0099d7;
        border: solid 1.9px;  
        position: relative;
        left: -2rem;
        margin-top:1rem;
    } */
    /* .img-download:hover{
        background-size: 75% !important;
        margin-top:1rem;
    }
        
    .img-download:active{
        background-size: 75% !important;
        margin-top:1rem;
    } */
    .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
}
@media(max-width: 1440px){
    /* .bgdownload{
        left:0.3rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
    /* .buttonIcon.btn-expand{
        margin-left: 2.5rem;
        padding: 10.5px -9px 8px 9px;
        position: absolute;
        margin-top:1rem;
        width:43px;
        height: 43px;
     }
     .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: -1.2rem;
        left: 0rem;
    } */
    /* .img-download{
        padding: 26.5px 29px 19px 28px;
        background-size: 75% !important;
        border-color: #0099d7;
        border: solid 1.9px;  
        position: relative;
        left: -2rem;
        margin-top:1rem;
    }
    .img-download:hover{
        background-size: 75% !important;
        margin-top:1rem;
    }
        
    .img-download:active{
        background-size: 75% !important;
        margin-top:1rem;
    } */
    .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
    .buttonIcon > img{
        width: 90%; 
    }
    .roundPopover.popover{
        width: 90%;
    }
}
@media(max-width: 1366px){
    /* .bgdownload{
        left: -0.1rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       }
    .btdownload:hover{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 80% !important;  
        margin-top: 0rem;
        margin-left: 0.5rem !important;
    }
    .btdownload:active{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 80% !important;  
        margin-top: 0rem;
        margin-left: 0.5rem !important;
    }
    .btdownload{
        background: url('./img/bt-download-normal.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        border-radius: 27%;
       background: #0099d7;
       color: #fff !important;
       padding: 26.5px 29px 19px 28px;
       border: none !important;
        background-size: 80% !important; 
        margin-top: 0rem;
        margin-left: 0.5rem !important;
        
    }
    
    .img-download{
        padding: 26.5px 29px 19px 28px;
        background-size: 75% !important;
        border-color: #0099d7;
        border: solid 1.9px;  
        position: relative;
        left: -2rem;
        margin-top:1rem;
    }
    .img-download:hover{
        background-size: 75% !important;
        margin-top:1rem;
    }
        
    .img-download:active{
        background-size: 75% !important;
        margin-top:1rem;
    }
    .img-download-bt{
        margin-top: 1rem;
    }
    .img-download-bt:hover{
        margin-top: 1rem;
    } */
    
    /* .buttonIcon.btn-expand{
       margin-left: 2.5rem;
       padding: 10.5px -9px 8px 9px;
       position: absolute;
       margin-top:1rem;
       width:43px;
       height: 43px;
    }
    .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: -1.2rem;
        left: 0rem;
    } */
    .popover{
        margin-left:-1.8rem;
    }
    .roundPopover.popover{
        border-radius: 50px;
        padding:15px;
        width: 90%;
        /* margin-left:-0.2rem; */
        margin-left:-1.3rem;
        position: relative;
        z-index: 2000;
    }
    
    .bs-popover-top .arrow::before{
        /* margin-left:-0.5rem; */
    }
    .bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after{
        /* margin-left:-0.5rem; */
    }
    .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
    .buttonIcon > img {
        width: 90%;
    }
 
}
@media(max-width:1200px){
    /* .bgdownload{
        left: -0.5rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
       .roundPopover.popover{
        width: 90%;
    }
}
@media(max-width:1100px){
    /* .bgdownload{
        left: -0.9rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
       .roundPopover.popover{
        width: 90%;
    }
}
@media(max-width:1024px){
 
     .buttonIcon.btn-expand{
        margin-top: 1rem; 
     }
     .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
    /* .bgdownload{
        left: 0.4rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       } */
}

@media(max-width: 898px){
    /* download */
    /* .img-download{
        padding: 32.5px 29px 19px 28px;
        background-size: 75% !important; 
        margin-left: 0.4rem !important;
         margin-top: 0rem;
    }
    .img-download:hover{
        margin-left: 0.4rem !important;
        margin-top: 0rem;
        background-size: 75% !important;
    }
    .img-download:active{
        margin-left: 0.4rem !important;
         margin-top: 0rem;
    }
    .img-download-bt{
        margin-top: 1rem;
        margin-left: -2.5rem;
    }
    .img-download-bt:hover{
        margin-top: 1rem;
        margin-left: -2.5rem;
    } */
    .roundPopover.popover{
        border-radius: 50px;
        padding:10%;
        width: 52px;
        margin-left:-1.3rem;
    }
    /* .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: 0.15rem;
        left: 0.15rem;
    } */
    /* .buttonIcon.btn-expand{
       position: relative;
       margin-left: 0.3rem;
    } */
    /* .buttonIcon.btn-expand{
        margin-left: 0.3rem;
        padding: 12.5px 11px 9px 9px;
        position: relative;
        top: 1.8rem;
     }
    .buttonIcon.btn-expand:active{
        background: #0099d7;
        color: #fff !important;
        border-color: #0099d7;
        margin-left: 0.3rem;
    }
    
    .buttonIcon.btn-expand:hover{
        background: #0099d7;
        color: #fff !important;
        border-color: #0099d7;
        margin-left: 0.3rem;
    } */
    .btn-compartilhar{
        margin-left: -1rem;
        background-size: 75% !important;
    }
    .btn-compartilhar:hover{
        margin-left:-1rem;
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        margin-left: -1rem;
        background-size: 75% !important;
    }
    
    /* .popOverButtons.list-group-item .btdownload {
        margin-left: -1rem !important;
    } */
}
@media(max-width:768px){
    /* .img-download{
        margin-left: 1.8rem !important;
        left:0.2rem;
        top:1rem;
    }
    .img-download:hover{
        margin-left: 1.8rem !important;
        left:0.2rem;
        top:1rem;
    }
    .img-download:active{
        margin-left: 1.8rem !important;
        left:0.2rem;
        top:1rem;
    }
    .img-download:hover{
        margin-left: 1.8rem !important;
        top:1rem;
    }
    .img-download:active{
        margin-left: 1.8rem !important;
        top:1rem;
    } */
    .modal-content{
        width: 100%;
    }
    /* .img-download-bt{
        margin-top: 1rem;
    }
    .img-download-bt:hover{
        margin-top: 1rem;
    } */
    .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
    /* .bgdownload{
        left: -0.1rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       }
    
    .popOverButtons.list-group-item .btdownload {
        margin-left: -.6rem !important;
    } */

    .holder-image-size-buttons + div + div {
       display: none;
    }
}

@media(max-width: 550px){
    /* download */
    /* .btdownload{
        margin-left: 0rem !important;
        margin-top: 0.6rem;
    }
    .img-download{
        padding: 32.5px 29px 19px 28px;
        background-size: 75% !important; 
        margin-left: 1.8rem !important;
         margin-top: 0rem;
         top:0rem;
    }
    .img-download:hover{
        margin-left: 1.8rem !important;
        margin-top: 0rem;
        background-size: 75% !important;
        top:0rem;
       
    }
    .img-download:active{
        margin-left: 1.8rem !important;
         margin-top: 0rem;
         background-size: 75% !important;
         top:0rem;
    }
    .img-download-bt{
        margin-top: 0rem;
        margin-left: -2rem;
    }
    .img-download-bt:hover{
        margin-top: 0rem;
        margin-left: -2rem;
    } */
    .roundPopover.popover{
        border-radius: 50px;
        padding:15px;
        width: 93%;
        margin-left:-0.4rem;
    }
    /* .buttonIcon.btn-expand .brand-icon.is-maximize:before{
        position: relative;
        top: 0.15rem;
        left: 0.15rem;
    } */
    /* .buttonIcon.btn-expand{
       position: relative;
       margin-left: 0.3rem;
       top:0rem;
    } */
    /* .buttonIcon.btn-expand:active{
        background: #0099d7;
        color: #fff !important;
        border-color: #0099d7;
        margin-left: 0.3rem;
    }
    
    .buttonIcon.btn-expand:hover{
        background: #0099d7;
        color: #fff !important;
        border-color: #0099d7;
        margin-left: 0.3rem;
    } */
    .btn-compartilhar{
        margin-left: -0.7rem;
        background-size: 75% !important; 
        margin-top:-0.1rem;
    }
    .btn-compartilhar:hover{
        margin-left:-0.7rem;
        background-size: 75% !important; 
        margin-top:-0.1rem;
    
    }
    .btn-compartilhar:active{
        margin-left:-0.7rem;
        background-size: 75% !important; 
        margin-top:-0.1rem;
    }
    .col-2{
        max-width: none;
    }
    /* .bgdownload{
        left: 0.4rem !important;
        top: 0.5rem;
        position: relative;
        background-size: 70% !important; 
       }

    .popOverButtons.list-group-item .btdownload {
        margin-left: -.3rem !important;
    } */


    .small-image .holder-buttons div[id*='cartButtonContainer'].buttonContainer {
        top:-5.2rem;
        left: 4.7rem !important;
        margin-bottom: 0;
        margin-top:.5rem;
    }

    .small-image .holder-buttons #cartButtonContainer.buttonContainer #cartButtonContainerbutton,
    .play-btn + .short-image .small-image .holder-buttons #cartButtonContainer.buttonContainer #cartButtonContainerbutton {
      width: 42px;
       max-width: 48px;
    }

    .buttonContainer .cart-mark  {
        right: -.7rem;
    }
    .small-image .holder-buttons .buttonContainer .cart-mark  {
        right: auto;
        left:-.7rem;
    }

    #downloadBondary  .popover-inner .list-group-item,
    #shareContainer  .popover-inner .list-group-item {
        padding: 0 !important;
        margin: .3rem 0
    }
}
@media(max-width: 414px){
    /* .btdownload{
        margin-left: 0rem !important;
    }
    .btdownload:hover{
        margin-left: 0rem !important;
    }
    .btdownload:active{
        margin-left: 0rem !important;
    }
    .img-download{
        margin-left: 1.8rem !important;
    }
    .img-download:hover{
        margin-left: 1.8rem !important;
    }
    .img-download:active{
        margin-left: 1.8rem !important;
    }
    .bgdownload{
        left: 0.2rem !important;
        position: relative;
        background-size: 70% !important; 
       }
       .bgdownload:hover{
        left: 0.2rem !important;
        position: relative;
        background-size: 70% !important; 
       } */
}

.text-fav {
    font-size: 1.5rem;
    margin: 0;
}

@media(max-width: 576px){
    
    .newsButtons{
        padding-right:6px;
    }

    .roundPopover.popover{
        width: 93%;
        padding: 10px
    }

    /** DOWNLOAD BUTTON */
    .popOverButtons.list-group-item .btdownload {
        width: 48px;
        padding: 0;
    }
    /* .img-download:click{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        background-size: 75% !important;  
    }
    .img-download:hover{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        background-size: 75% !important;  
    }
    .img-download:active{
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        background-size: 75% !important;  
    }
    .img-download{
        background: url('./img/bt-download-normal.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        background-size: 75% !important;  
    } */
    .btn-compartilhar:hover{
        background-size: 75% !important;
    }
    .btn-compartilhar:active{
        background-size: 75% !important;
    }
    .btn-compartilhar{
        background-size: 75% !important;
    }
    .buttonIcon > img {
        width: 80%;
    }
    /* .bgdownload{
        left: 0.8rem;
        position: relative;
        background-size: 70% !important; 
       }
       .bgdownload:hover{
        left: 0.3rem;
        position: relative;
        background-size: 70% !important; 
       } */

}
@media(max-width: 523px){
    /* .bgdownload{
        left: 0.1rem !important;;
        position: relative;
        background-size: 70% !important; 
       } */
       
}
@media(max-width: 412px){
    /* .bgdownload{
        left: -0.1rem !important;;
        position: relative;
        background-size: 70% !important; 
       } */
       
}
@media(max-width: 414px){
    /* .bgdownload{
        left: -0.1rem !important;;
        position: relative;
        background-size: 70% !important; 
       } */
       
}

@media(max-width: 375px){
    /* .btdownload{
        margin-left: -0.5rem !important;
    }
    .btdownload:hover{
        margin-left:  -0.5rem !important;
    }
    .btdownload:active{
        margin-left:  -0.5rem !important;
    }
    
    .img-download{
        margin-left: 1.8rem !important;
        background: url('./img/bt-download-normal.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        background-size: 75% !important;  
    }
    .img-download:hover{
        margin-left: 1.8rem !important;
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        background-size: 75% !important; 
    }
    .img-download:active{
        margin-left: 1.8rem !important;
        background: url('./img/bt-download-hover.svg') !important;
        background-repeat: no-repeat !important;
        width:48px;
        height: 48px;
        background-size: 75% !important; 
    }
    .bgdownload{
        left: 0.3rem !important;
        position: relative;
        background-size: 70% !important; 
       } */
}
@media(max-width: 360px){
    /* .bgdownload{
        left: 0.2rem !important;
        position: relative;
        background-size: 70% !important; 
       } */
}
@media(max-width: 320px){
    .roundPopover.popover{
        width: 93%;
        padding: 10px
    }
    /* .roundPopover.popover {
        width: 114%;
        padding: 8px;
    } */
    .bs-popover-top .arrow::before{
        /* margin-left:-0.5rem; */
    }
    .bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after{
        /* margin-left:-0.5rem; */
    }
    .btn-compartilhar {
        margin-left: -1rem;
    }
    .btn-compartilhar:hover {
        margin-left: -1rem;
    }

}