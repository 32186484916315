.card {
    background: #f2f2f2;
    border: none;
    padding: 1.5rem
}

.card-title {
    font-family: 'VWHead', sans-serif;
    font-size: 2rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #0099d7;
}

.vw-loanrequest .card .card-value {
    font-size: 1.4rem;
    color: #666666
}

.vw-loanrequest .card .card-value .card-txt-value {
    color: #000000 !important;
    font-size: 1.4rem;
    font-weight: 500;
}

.vw-text-body {
    color: #000000 !important;
}

/*
* Input Radio
*/

.inputRadioForm {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    border: 0.03em solid;
    vertical-align: middle;
}

.inputRadioForm:before {
    content: '';
    display: block;
    width: 50%;
    height: 50%;
    margin: 25% 25% 25% 25%;
    border-radius: 25px;
}

.inputRadioForm:checked:before {
    background: #0199da;
}

.inputRadioForm:checked+label {
    color: #000000;
}

.inputRadioForm+label {
    color: #666666;
}

.vw-loanrequest .form-check-label {
    margin-left: 1em !important;
}

.label-required {
    font-size: 1.1rem;
    color: #666
}

.valid-cpf-label {
    color: #0099d7;
    font-weight: bold;
}

.valid-cpf-label span {
    color: #000;
    font-weight: normal;
}

.invalid-cpf-label {
    color: #f00;
    font-weight: bold;
}

.invalid-cpf-label a {
    color: #0099d7 !important;
}

.margin-bottom15rem {
    margin-bottom: 1.5rem !important;
}

@media (max-width: 544px) {
    .vw-loanrequest .form-check-label {
        margin-left: .5rem !important
    }

    .vw-loanrequest .container-loanStep2 .card-body {
        padding: .5rem
    }
}