.vw-loanlist .loan-number-title {
    font-size: 2rem;
    color:#0199da;
    font-weight: bold;
    margin-bottom: .5rem !important
}

.vw-loanlist .loan-date-resume {
    font-size: 1.5rem;
    color:#000;
    font-weight: bold;
    padding-bottom:1.5rem;
    margin-bottom:2rem;
    border-bottom: solid 0.3px rgba(144, 144, 144, 0.3);
}

.vw-loanlist .loan-user-title {
    font-size: 1.4rem;
    color: #888;
}

.vw-loanlist .loan-user-title .loan-user {
    color: #000
}