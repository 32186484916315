.termos-txt{
   /* left:3rem; */
   /* line-height: 0.5rem !important; */
   margin-bottom: 53rem;
   
}
.termos-txt .vw-page-subtitle:hover{
    color: #0099DA;
    white-space: nowrap;
}
.termos-txt .vw-page-subtitle{
    font-size: 15px;
}
.vw-termos .vw-page-subtitle{
    padding-bottom: 0 !important;
}
.vw-termos .termos-txt-subtitulo{
        font-family: VWHeadOffice;
        color: #000000;
        font-size: 1.5rem;
}
