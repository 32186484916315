.vw-media-C{
    margin-top: 3rem;
}
.padding-component{
    padding: 0.5rem !important;
}
.vw-media-C .btn-media{
    background: #0199da;
    width: 190px;
    height: 4.2rem;
    line-height: 4rem;
    border-radius: 28px;
    margin:2rem auto;
    text-align: center;
  }
  .vw-media-C .btn-media-txt{
  font-family:VWTextOffice, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 3px;
  margin-left: 0rem;
  color: #fff;
  background: #0199da;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  vertical-align: top;
  }
  .vw-media-center-Gallery .btn-Gallery {
    position: relative;
    left: -0.4rem;
   }
   .vw-media-center-Gallery .slick-dots {
    margin-left: -11vw !important;
  }
  @media(max-width:1440px){
    .vw-media-center-Gallery .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       .vw-media-center-Gallery .slick-dots {
        margin-left: -11vw;
      }
  }
@media(max-width:1280px){
    .vw-media-center-Gallery .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       .vw-media-center-Gallery .slick-dots {
        margin-left: -5vw;
      }
  }

  @media(max-width:1024px){
    .vw-media-center-Gallery .btn-Gallery {
        position: relative;
        left: -0.3rem;
       }
       .vw-media-center-Gallery .slick-dots {
        margin-left: -3vw;
      }
  }
  @media(max-width:992px){
    .vw-media-center-Gallery .btn-Gallery {
        position: relative;
        left: 0.5rem;
       }
       .vw-media-center-Gallery .slick-dots {
        margin-left: -0.7vw;
      }
  }
  @media(max-width:960px){
    .vw-media-center-Gallery .btn-Gallery {
        position: relative;
        left: 1.1rem;
       }
       .vw-media-center-Gallery .slick-dots {
        margin-left: 2vw;
      }
  }
  @media(max-width:768px){
    .vw-media-center-Gallery .btn-Gallery {
        position: relative;
        left: -0.2rem;
       }
       .vw-media-center-Gallery .slick-dots {
        margin-left: 2vw;
      }
  }

  
