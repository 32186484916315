.vw-checklist .loan-number-title {
    font-size: 2rem;
    color:#0199da;
    font-weight: bold;
    margin-bottom: .5rem !important
}

.vw-checklist .loan-date-resume {
    font-size: 1.5rem;
    color:#000;
    font-weight: bold;
    padding-bottom:1.5rem;
    margin-bottom:2rem;
    border-bottom: solid 0.3px rgba(144, 144, 144, 0.3);
}

.vw-checklist .loan-user-title {
    font-size: 1.4rem;
    color: #888;
}

.vw-checklist .loan-user-title .loan-user {
    color: #000
}


@media (max-width: 544px) {
    .vw-checklist .page-title {
       font-size: 3rem
    }
}