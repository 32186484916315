.vw-areaLogadaComponent {
    box-shadow: 0 0.5px 0.5px 0 rgba(149, 149, 149, 0.5);
}

.vw-areaLogadaComponent .title-logged {
    font-family: 'VWTextOffice';
  font-size: 3rem;
  font-weight: 400;
  color: #0199da;
}

.vw-areaLogadaComponent .img-hand {
  width:50%;
  height: 100%;
  margin:0 auto 0 40%;


}

.vw-areaLogadaComponent .btn-primary .arrow-foward {
  top:.6rem
}


@media (max-width: 544px) {
  .vw-areaLogadaComponent .img-hand {
    margin: 0 auto 0 30%;
    width: 80%;
    height: 100%;
  }

  .vw-areaLogadaComponent .title-logged {
    font-size: 2rem
  }

  .vw-areaLogadaComponent  .vw-page-subtitle {
    font-size: 1.4rem
  }

  .vw-areaLogadaComponent  .btn-primary {
   min-width: 150px;
  }
}