
.vw-stepwizard {
    display: table;
    width: 200px;
    position: relative;
    margin-left: -1rem;
}

.vw-stepwizard .stepwizard-step p {
    margin-top: 0px;
    color:#666;
  }
  .vw-stepwizard .stepwizard-step span {
   font-size: 1.3rem;
   font-weight: bold !important;
  }
  .vw-stepwizard .stepwizard-row {
      display: table-row;
  }

  .vw-stepwizard .stepwizard-step .btn-circle[disabled] {
      /*opacity: 1 !important;
      filter: alpha(opacity=100) !important;*/
  }
  .vw-stepwizard .btn-circle.disabled, 
  .vw-stepwizard .btn-circle[disabled], 
  .vw-stepwizard fieldset[disabled] .btn-circle {
      opacity:1 !important;
      border-radius: 50%;
      background-color: #b2e0f3 !important;
  }
  .vw-stepwizard .stepwizard-row:before {
      top: 14px;
      bottom: 0;
      left: 20px;
      position: absolute;
      content:" ";
      width: calc(100% - 40px);
      height: 2px;
      background-color: #0199da;
      z-index: 0;
  }
  .vw-stepwizard .stepwizard-step {
      display: table-cell;
      text-align: center;
      position: relative;
  }
  .vw-stepwizard .btn-circle {
      width: 30px;
      height: 30px;
      display: inline-block;
      color:#fff;
      text-align: center;
      padding: 6px 0;
      font-size: 12px;
      font-weight: bold;
      line-height: 1.428571429;
      border-radius: 50%;
      background-color: #0199da !important;
  }

  @media (max-width: 554px) {
    .vw-stepwizard .card-body {
        padding: .25rem;
    }
  }