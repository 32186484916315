.cookiesalert-container{
  width: 100vw;
  height: 100vh;
  background:rgba(0,0,0,0.6);
  position: fixed;
  top:0;
  left:0;
  z-index: 99999;
}

.cookiesalert-container .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  background: #000;
  color:#fff;
  font-size: 1.7rem;
  padding: 25px 25px 15px 25px;
  margin: 0;
  max-width: 100vw;
}

.cookiesalert-container .content .vw-page-subtitle {
  color: #fff;
  font-size: 2.5rem;
  padding-bottom: 15px;
}
.cookiesalert-container .content a{
  text-decoration: underline
}

.cookiesalert-container .content .close {
  color: #fff;
  margin-right: 1.5rem;
}

