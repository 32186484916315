.vw-bodys {
	margin-left: 100px !important;
}

.anchor{
	display: block;
	height: 80px; /*same height as header*/
	margin-top: -80px; /*same height as header*/
	visibility: hidden;
  }

  .vw-home .newscomponent-container .btn-primary  {
	margin-left: -3rem;
}

  .vw-tabbar.fixed-top + .row .homeMobile{
	  padding-top: 60px
  }
  .homeMobile {
	  width:100vw;
	  overflow: hidden;
	  margin-top: -5px;
	  padding-top: 60px
  }


  .homeMobile {
	width:6000px;
	/* overflow: scroll; */
	position: relative;
	transition: all 500ms;
}
 	 .homeMobile > div {
		width:100vw;
		/* min-height: 90vh; */
		display: inline-block;
		vertical-align: top;
		transition: all 500ms;
	}


  	.homeMobile .slick-slider {
		width:100vw;
		/* min-height: 15vh; */
	}

		.homeMobile .vw-social .slick-slider {
			min-height: 19vh;
		}
	.container-fluid .homeMobile .slick-dots {
		margin-left: 2.4rem;
	}

	.homeMobile .page-title {
		text-align: left;
	}

	.homeMobile .slick-slider .slick-list {
		width: 100%;
	}

	.homeMobile  .btn-primary  {
		margin-left: .5rem;
	}
	 .container-fluid .homeMobile .newscomponent-container {
		padding-left: 1.7rem !important;
		width: 96%;
	 }

	 .container-fluid .homeMobile .holderSliderHome {
		height: auto;
		position: absolute;
		/* left: 100vw; */
		/* opacity: 0; */
		/* max-height: 10%; */
	 }
	 .container-fluid .homeMobile .holderSliderHome:nth-child(1) {
		left: 0;
	 }
	 .container-fluid .homeMobile .holderSliderHome:nth-child(2) {
		left: 100vw;
	 }
	 .container-fluid .homeMobile .holderSliderHome:nth-child(3) {
		left: 200vw;
	 }
	 .container-fluid .homeMobile .holderSliderHome:nth-child(4) {
		left: 300vw;
	 }
	 .container-fluid .homeMobile .holderSliderHome:nth-child(5) {
		left: 400vw;
	 }
	 .container-fluid .homeMobile .holderSliderHome.slide-active {
		position: relative;
		height: auto;
		max-height: 100%;
		padding-bottom: 1rem;
		/* opacity: 1; */
		/* left: 0; */
	 }
	 .container-fluid .homeMobile .newsItem-container {
		width: 100%;
		margin-left: auto;
	 }

	 .container-fluid .homeMobile .newsItem-container .col-image{
		/* flex: 0 0 100%; */
		/* max-width: 100%; */
	 }
	 .container-fluid .homeMobile .newscomponent-container .slick-slider {
		width:93vw;
	}
	 .container-fluid .homeMobile .CarouselGallery{
		padding-left:2rem !important;
		width: calc(100vw - 1.8rem);
	}

	.container-fluid .homeMobile .Speak-container {
		padding-left:1rem;
		padding-right:1.5rem;
	}

	.homeMobile .CarouselGallery .slick-slide > div > div:first-child {
		overflow: hidden;
		border-radius: 9px;
		padding: 0;
		margin: 0 15px;
	}
	
	.container-fluid .homeMobile .car-container {
		padding-left: 0rem !important;
	}

	.container-fluid .homeMobile .vw-car .car-margin > .page-title  {
        margin-left:0rem
	}
	
	.container-fluid .homeMobile .car-container .car-inside {
		margin-top: 0;
	}
	.container-fluid .homeMobile .car-container .car-inside .slick-slide{
		width: 92vw !important;
	}
	.container-fluid .homeMobile .car-container .car-inside .slick-slide .page-title {
		text-align: center;
	}
	.container-fluid .homeMobile  .Speak-container {
		padding-top: 0;
	}

	.homeMobile .vw-social {
		padding-top: 2rem;
	}
	.homeMobile .vw-social ,
	.homeMobile .vw-social .page-title {
		margin-top: 0;
	}

	.homeMobile  .vw-social .title-social {
		margin-left: 4rem;
	}

	
	@media (min-width: 1366px) {
	
		.homeMobile .slick-slider {
			width:100vw;
			min-height: 25vh;
		}
	
			.homeMobile .vw-social .slick-slider {
				min-height: 39vh;
			}
			.vw-home .newscomponent-container .btn-primary {
				margin-left: -3.5rem;
			}
	}

	@media (min-width: 544px) {
		.homeMobile .slick-slider {
			/* min-height: 50vh; */
		}
	}

	@media (min-width: 992px) {
		.homeMobile .CarouselGallery .short-image .cropped-image--sizer,
		.homeMobile .CarouselGallery .short-image > img {
			min-height: 240px;
		}
	}

	@media (max-width: 992px) {
		.vw-home .newscomponent-container .btn-primary{
			margin-left: 1.5rem;
		}
		.homeMobile .CarouselGallery .short-image .cropped-image--sizer,
		.homeMobile .CarouselGallery .short-image > img {
			min-height: 200px;
		}
	}

	@media (max-width: 544px) {
		.vw-home .newscomponent-container .btn-primary{
			margin-left: -3rem;
		}
	}
	@media(max-width:414px){
		.vw-home .newscomponent-container .btn-primary {
		  margin-left: -2rem;
	  }
	  }
	  @media(max-width:412px){
		.vw-home .newscomponent-container .btn-primary {
		  margin-left: -1.9rem;
	  }
	  }
	@media(max-width:375px){
		.vw-home .newscomponent-container .btn-primary {
		  margin-left: -1.9rem;
	  }
	  }
	  @media(max-width:320px){
		.vw-home .newscomponent-container .btn-primary {
		  margin-left: -2rem;
	  }
	  }

