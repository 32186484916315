.expand__imageHeader{
  width: 100%;
  height: 100%;
  padding: 0;
}

.expand__imageHeader img{
  position: relative!important;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  width: 100%;
}

.expand__imageHeader video{
  position: relative!important;
  width: 100%;
}

.expand__imageHeader > .modal-title{
  width: 100%;
}

.expand__menuImages {
  margin-right: 2rem;
}
@media(max-width: 768px){
  .expand__menuImages{
    text-align: center;
  }
}

.expand__imageHeader > .close {
  position: absolute;
  font-size: 4rem;
  right: 1rem;
  top: 0;
}

.expand__imageHeader > .close:focus {
  outline: none;
  background: transparent;
}

.imageIcon {
  height: 3rem;
  width: 3rem;
  border: none;
  margin: 0rem 0.5rem; 
  outline: none!important;
  padding-right: 1rem;
}

.imageDownloadIcon {
  background-image: url('./assets/icone-foto.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: 1rem;
  width: 1rem;
  border: none;
  margin-right: 0.5rem; 
  outline: none!important;
  padding-right: 1rem;
}

.download:not(.confirmationModal){
  background: transparent;
  background-image: url('./assets/bt-download-hover.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
/* .download:hover{
  background-image: url('./assets/bt-download-normal.svg');
} */
.download:hover:not(.confirmationModal){
  background-image: url('./assets/bt-download-normal.svg' );
}
.download:click:not(.confirmationModal){
  background-image: url('./assets/bt-download-press.svg');
}

.cart{
  background-image: url('./assets/logo-vw@2x.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.share{
  background: transparent;
  background-image: url('./assets/bt-compartilhar-normal.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.share:hover{
  background-image: url('./assets/bt-compartilhar-hover.svg');
}


.imageSize{
  text-align: left;
}

.imageSize:hover{
  background: #efefef;
}

.imageSize:hover{
  background: #efefef;
}
.imageSize__size{
  font-family: VWHeadOffice;
  color: #000000;
  font-weight: bold;
  font-size: 1rem;
}

.imageSize:hover > .imageSize__size {
  /* color: white; */
}

.imageSize__value {
  font-family: VWTextOffice;
  font-size: 0.875rem;
  color: #666666;
  line-height: 2;
}

.imageSize:hover > .imageSize__value {
  /* color: white; */
}

.popover-body{
  padding: 0;
}

.shareIcon{
  padding:0;
  margin:0;
  border:0;
  background: transparent;
}

.small-image  {
  display: block;
}
.small-image .holder-buttons {
  display: none
}
.small-image .holder-buttons .buttonContainer img{
 max-width: 48px;
 position: relative;
}
.small-image:hover .holder-buttons,
.play-btn:hover + .short-image .small-image .holder-buttons {
  display: block;
  position: absolute;
  bottom: 4%;
  left: 50%;
  margin-left: calc(-42% + 1px);
  width: 80%;
  height: 50px;
  z-index: 999;
  text-align: center;
}

.small-image .holder-buttons .buttonContainer .roundPopover.popover{
  border-radius: 50px;
  padding:10px;
  width: 64px;
  max-width: 100%;
}
.small-image .holder-buttons div[id*='cartButtonContainer'] .popover{
  border-radius: 50px;
  padding:0 !important;
  width: 94px !important;
  min-width: 115px !important;
  max-width: 160px !important;
  height: auto;
}

.small-image .holder-buttons div[id*='cartButtonContainer'] .popover .popover-inner {
overflow: hidden;
}
.small-image .holder-buttons div[id*='cartButtonContainer'] .popover .popover-inner .popOverButtons {
  /* padding: 1rem !important; */
  background: transparent
}
.small-image .holder-buttons div[id*='cartButtonContainer'] .popover .text-fav {
  font-size: 1.4rem
}

.small-image .holder-buttons .buttonContainer .popOverButtons img {
  max-width: 48px;
}

.small-image:hover .holder-buttons > div,
.play-btn:hover + .short-image .small-image .holder-buttons > div,
.play-btn + .short-image .small-image:hover .holder-buttons > div {
  width:48px;
  height: 48px;
  display: inline-block;
  margin: 0 1rem;
}
.small-image:hover .holder-buttons > div:nth-child(3),
.play-btn:hover + .short-image .small-image .holder-buttons > div:nth-child(3),
.play-btn + .short-image .small-image:hover .holder-buttons > div:nth-child(3) {
  vertical-align: top;
  margin-top: 0rem !important;
}

/* .play-btn + .short-image .small-image .holder-buttons > div:nth-child(2) {
  margin-top: 4px;
}

.play-btn:hover + .short-image .small-image .holder-buttons > div:nth-child(1) {
  vertical-align: top;
  margin-top: -5px;
}

.play-btn:hover + .short-image .small-image .holder-buttons > div:nth-child(2) {
  vertical-align: top;
  margin-top: 4px;
} */

.small-image .holder-buttons .buttonContainer > div {
 position: relative;
 height: 48px;
}

.small-image .holder-buttons .buttonContainer > div:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
}

.buttonContainer > div {
  max-width: 48px;
  width: 48px;
  height: 48px;
}

@media(max-width: 992px){
  .small-image .holder-buttons,
  .play-btn + .short-image .small-image .holder-buttons {
    width: 50px !important;
    height: 150px !important;
    display: block;
    position: absolute;
    top: 50% !important;
    bottom: auto !important;
    left:1rem !important;
    margin-left: 0 !important;
    margin-top: -75px !important;
    z-index: 90;
  }
  .small-image .holder-buttons .buttonContainer,
  .play-btn + .short-image .small-image .holder-buttons .buttonContainer {
    margin-top: 5px;
  }
  .small-image .holder-buttons .buttonContainer:last-child,
  .play-btn + .short-image .small-image .holder-buttons .buttonContainer:last-child{
    margin-top: 0;
  }
  .buttonContainer,
  .buttonIcon.btn-expand {
    width: 3.8rem !important;
    height: 5rem !important;
    margin-right: 1.5rem;
    max-width: 48px;
    max-height: 48px;
    padding: 0;
  }
  #headerCartButtonContainer {
    width: 3.8rem !important;
    height: 3.8rem !important;
    position: relative;
  }
  #headerCartButtonContainer .cart-has-item {
    right:4px;
  }
  .vw-header .cartButtonImage {
    margin-top: -3px;
    margin-left: 1px;
  }
  #headerCartButtonContainer {
    margin-right: -10px;
  }

  .buttonContainer .bs-popover-right {
    min-width: 160px;
    margin-left: .5rem;
    margin-top:1px;
  }

  .buttonContainer .popover-inner {
    margin:.5rem;
  }

  .buttonContainer .popover-inner .list-group-item {
    border-width: 0;
  }

  .buttonContainer .popover-inner .list-group-item:first-child {
    border-width: 0;
  }
  .buttonContainer .popover-inner .list-group-item .imageSize__size {
    font-size: 1.2rem;
    font-weight: normal;
  }
  .buttonContainer .popover-inner .list-group-item .imageSize__value {
    font-size: 1rem;
    color: #b5b5b5;
  }
  .buttonContainer .popover-inner .list-group-item .imageDownloadIcon {
    height: 1.5rem;
    width: 1.5rem;
    padding: .1rem 0 0 1.7rem;
    background-size: 13px;
  }
  .buttonIcon.btn-expand {
    padding: 10.5px 7px 7px 7px;
  }

  .small-image:hover .holder-buttons > div,
  .play-btn + .short-image .small-image:hover .holder-buttons > div,
  .play-btn:hover + .short-image .small-image:hover .holder-buttons > div,
  .play-btn:hover + .short-image .small-image .holder-buttons > div {
    width:48px;
    display: block;
    margin: 5px 0 0 0;
  }
  .small-image:hover .holder-buttons > div:nth-child(3) {
    /* margin-top: .8rem !important; */
  }
}