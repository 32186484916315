
.vw-media-center{
    margin-top: 3rem;
}
.padding-component{
    padding: 0.5rem !important;
}
.vw-media-center .page-title{

margin-bottom: 3rem;
}

.padding-0 .newscomponent-container.isVertical {
    padding-left: 0;
}
.padding-0 .newsItem-container {
    margin-left: 0;
    margin-right: 0;
}

.padding-0 .isVertical .newscomponent-item {
    margin-right:0;
    padding-left:0;
}
