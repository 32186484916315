/* Normal */

.vw-side-menu img {
    display: block;
    margin: 0 auto 2rem auto;
}

.title1 {
    display: none;
}

.title2 {
    display: none;
}

.vw-side-menu {
    min-height: 100vh;
    text-align: center;
    width:115px;
}

.vw-side-menu-content {
    width: auto;
    height: 100%;
    position: absolute;
    background: #ffffff;
    border-right: 1px solid #DEE1E3;
    border-left: 1px solid #DEE1E3;
    z-index: 101;
    /* height: 2058px; */
    /* z-index: 101; */
}

.vw-side-menu-content .nav {
    height: 100%;
}

.vw-side-menu-content[data-level='1'] .nav-item {
    width: 100%;
    /* z-index: 100; */
}
.vw-side-menu-content[data-level='1'] > .nav > .nav-item:first-child {
    max-height: 100px;
}
.vw-side-menu-content a {
    color: #808890;
    font-size: 13px;
    white-space: nowrap;
    padding-top: 0.5vh;
    padding-bottom: 1.2vh;
    margin-left: 4px;
    margin-right: 4px;
    border-bottom: 1px solid #DEE1E3;
    /* width: 209px  ; */
}
.vw-side-menu-content .vw-logo {
    padding: .5rem .5rem;
}
.vw-side-menu-content .vw-logo a {
    margin-left: 0;
    margin-right: 0;
    /* width: 209px  ; */
}
.vw-side-menu-content a:hover {
    color: #0099DA;
    font-size: 13px;
    white-space: nowrap;
    /* width: 59px  ; */
}

.vw-side-menu-content .brand-icon {
    display: block;
    font-size: 5.5vh;
    line-height: 3.8vh;
    height: 3.8vh;
}
.Volkswagen{
    text-align: center !important;
    position: relative;
    left:0;
    top:9px;
}
/* .vw-side-menu{
overflow: scroll;
} */

 /* .nav-item .arquivo{
   position: relative;
   top: -0.5rem;
} */


/*Data Level 2 */

.vw-side-menu-content[data-level='2'],
.vw-side-menu-content[data-level='3'] {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    visibility: hidden;
    /* transition: 0.2s 1s;
    transition-property: 'visibility'; */
    top:0;
}
.vw-side-menu-content[data-level='2'] {
    left: 113px;
}
.vw-side-menu-content[data-level='3'] {
    left: 412px;
}
.vw-side-menu-content[data-level='2'] .nav,
    .vw-side-menu-content[data-level='3'] .nav {
        height: auto;
        height: 100%;
        flex-direction: row!important;
        display: block;
        width: 320px;
        overflow: auto;
    }
    .vw-side-menu-content[data-level='3'] .nav {
        width: auto;
    }
    .vw-side-menu-content[data-level='1'] > .nav > .nav-item:hover  {
        /* z-index: 120; */
    }
.vw-side-menu-content[data-level='1'] .nav-item.active .vw-side-menu-content[data-level='2'] {
    /*
    display: block;
    */
    left: 113px;
    top: 0px;
    width: 300px;
    /* transition-delay: 0s; */
    visibility: visible;
}
.vw-side-menu-content[data-level='1'] .nav .nav-item:nth-child(1):hover + .nav-item  {
    /* z-index: 120; */
}
/* .vw-side-menu-content[data-level='1'] .nav-item:hover .vw-side-menu-content[data-level='2'] .nav-item:hover {
    width: 800px
} */
.vw-side-menu-content[data-level='2'] a,
.vw-side-menu-content[data-level='3'] a {
    text-align: left;
    margin-left: 10px;
    margin-right: 30px;
    padding-top: 10px;
    padding-bottom: 05px;
}

.vw-side-menu-content[data-level='2'] a label,
.vw-side-menu-content[data-level='3'] a label {
    line-height: 3;
    white-space: normal !important;
}

.vw-side-menu-content[data-level='2'] .nav-item:hover .nav-link,
.vw-side-menu-content[data-level='3'].show a:hover,
.vw-side-menu-content[data-level='2'].show a:hover label,
.vw-side-menu-content[data-level='3'].show a:hover label{
    white-space: normal !important;
}

.vw-side-menu-content[data-level='2'] .nav-align,
.vw-side-menu-content[data-level='3'] .nav-align {
    display: flex;
    /* align-items: center; */
}


/*Data Level 3 */
.vw-side-menu-content[data-level='2'] > .nav > .nav-item {
    /* z-index: 120; */
}
.vw-side-menu-content[data-level='1'] > .nav > .nav-item:hover .vw-side-menu-content[data-level='2'] > .nav > .nav-item:hover {
    /* z-index: 130; */
}
.vw-side-menu-content[data-level='2'] .nav-item.active .vw-side-menu-content[data-level='3'] {
    display: block;
    left: 412px !important;
    top: 0px;
    min-width: 300px;
    width: auto;
    visibility: visible;
    /* transition-delay: 0s; */
    /* z-index: 130; */
}
.vw-side-menu-content[data-level='3'] {
    position: fixed;
}
.is-arrow:before {
    font-family: "VW Icons";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    content: "";
    color: #0099DA;
    vertical-align: text-bottom;
}
.sidemenu-login{
    color: #0099DA;
}

.brand-icon.is-close-layer:before {
    display: none;
}

.overlay {
    position: fixed;
    /* Sit on top of the page content */
    display: block;
    /* Hidden by default */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 100;
    /* Specify a stack order in case you're using a different order for other elements */
}


/* IPad Pro */

@media (max-width: 992px) {
    .vw-side-menu {
        left: -400px;
    }
    .vw-side-menu.show {
        /* transition: 0.5s; */
        left: 0;
    }
    .vw-side-menu.hide {
        /* transition: 0.5s; */
        left: -400px;
    }
    .title1 {
        display: block;
    }
    .title2 {
        display: block;
        /* width: 70vw; */
    }
    .menu-head--title {
        font-weight: bold;
        color: #2F3538;
        font-size: 19px;
        position: absolute;
        width:65vw;
    }

    .vw-side-menu-content {
        width: 329px;
    }
    
    .vw-side-menu-content[data-level='2'],
    .vw-side-menu-content[data-level='3'] {
        left: -400px !important;
    }
    .vw-side-menu-content[data-level='2'] .nav,
    .vw-side-menu-content[data-level='3'] .nav{
       width: 100%;
    }
    .vw-side-menu-content[data-level='1'] .nav-item.active .vw-side-menu-content[data-level='2']:not(.hide),
    .vw-side-menu-content[data-level='2'] .nav-item.active .vw-side-menu-content[data-level='3']:not(.hide) {
        width:97vw !important;
        left: 0 !important
    }
    .vw-side-menu-content[data-level='1'] > .nav > .nav-item:first-child {
        max-height: 50px
    }
    .vw-side-menu-content a {
        width: -209px !important;
        font-size: 17px !important;
        left: 60px !important;
        padding-bottom: 0px;
        padding-top: 0px;
        margin-bottom: -5px;
        text-align: left;
    }
    .vw-side-menu-content .vw-logo {
        margin-top: 25px;
        margin-bottom: 0;
    }
    .vw-side-menu-content a:before {
        width: 389px !important;
        font-size: 17px !important;
    }
    .vw-side-menu-content[data-level='2'] a label,
    .vw-side-menu-content[data-level='3'] a label {
        line-height: 1.3;
    }
    .vw-side-menu-content li:first-child a {
        margin-left: -20px;
        margin-right: 0px;
    }
    .vw-side-menu img {
        display: none;
    }
    .brand-icon.is-close-layer:before {
        display: block;
        font-size: 16px;
        margin-right: -270px !important;
    }
    .vw-side-menu-content[data-level='1'] .brand-icon.is-close-layer:before {
        margin-top: -5px !important;
    }
    .vw-side-menu-content[data-level='2'] .brand-icon.is-close-layer:before,
    .vw-side-menu-content[data-level='3'] .brand-icon.is-close-layer:before {
        margin-top: -16px !important;
    }
    .brand-icon.is-close-layer {
        margin-top: 0;
        height: 0px;
        margin-left: 0 !important;
        text-align: end;
        width: 10px;
        position: absolute;
        top: 2.5rem;
        right: 29rem;
    }
    .brand-icon.is-models,
    .brand-icon.is-company,
    .brand-icon.is-technology,
    .brand-icon.is-e-mobility,
    .brand-icon.is-press-service {
        position: absolute;
        left:2rem;
    }
    .vw-side-menu-content a label {
        margin-left: 6rem !important;
    }
    
    .vw-side-menu-content .nav-link.active + .vw-side-menu-content[data-level='2'],
    .vw-side-menu-content[data-level='2'] .nav-link.active + .vw-side-menu-content[data-level='3']  {
        display: block;
    }
    .vw-side-menu-content .nav-link.active + .vw-side-menu-content[data-level='2'],
    .vw-side-menu-content[data-level='2'] .nav-link.active + .vw-side-menu-content[data-level='3'] {
        transition: 0.5s;
        left: 0px !important;
        width: 99vw !important;
        top: 0px !important;
    }
    .vw-side-menu-content[data-level='2'] .nav-link.active + .vw-side-menu-content[data-level='3'] {
        width: 98vw !important;
    }
    .vw-side-menu-content .nav-link + .vw-side-menu-content[data-level='2'],
    .vw-side-menu-content[data-level='2'] .nav-link + .vw-side-menu-content[data-level='3'],
    .vw-side-menu-content .nav-link + .vw-side-menu-content[data-level='2'].hide,
    .vw-side-menu-content[data-level='2'] .nav-link + .vw-side-menu-content[data-level='3'].hide {
        transition: 0.5s;
        left: -400px !important;
        width: 321px !important;
        top: 0px !important;
    }
    .vw-side-menu-content[data-level='1'] .nav-item:hover .vw-side-menu-content[data-level='2'] {
        
        /* transition: 0.5s; */
       
    }
    .vw-side-menu-content[data-level='1'] .vw-side-menu-content[data-level='2'] .nav-item:hover .vw-side-menu-content[data-level='3'] {
        /*
        left: 0 !important;
        width: 329px;
        */
    }
    .vw-side-menu-content[data-level='2'] .nav-item {
        position: relative;
    }
    .vw-side-menu-content[data-level='2'] a {
        height: 5rem;
        margin-left: -5px;
        padding-bottom: 5px;
        padding-top: 0;
    }
    .is-arrow:before {
        /* margin-top: -12.7px; */
        margin-left: -5px;
        position: absolute;
        top:50%;
    }
    .vw-side-menu-content[data-level='2'] a label{
        margin-left: 2rem !important;
        /* margin-top:-0.6rem; */
    }
    .vw-side-menu-content[data-level='2'] .nav-link {
        margin-top: 1rem;
        line-height: 1;
    }
     .vw-side-menu-content[data-level='2'] .nav-align {
        margin-left: 2rem;
        margin-top: 0.5rem;
        /* align-items: center; */
    }
    .vw-side-menu-content[data-level='3'] .nav-align {
        margin-left: 2rem
    }
    /*
    .nav-align label {
        margin-top: -19px;
        margin-bottom: 34px;
        padding-top: 0px !important;
        position: absolute;
        left: 9rem;
    } */
    .title-sidemenu{
        margin-top: -15px;
        /* margin-bottom: 34px; */
        padding-top: 0px !important;
        position: absolute;
        left: 4rem;
        top:0.6rem;
    }
    .menu-head--title:before {
        font-family: "VW Icons";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 30px;
        margin-top: -5.5px;
        margin-left: -2px;
        content: "\E92D";
        position: absolute;
        left: -55px;
        top:-0.5rem;
        color: #808890;
        transform: rotate(180deg);
        /* font-weight: bold; */
    }
    /* .vw-side-menu label {
        height: 10px;
        margin-bottom: 25px;
        margin-left: -20px;
    } */
    .txt-modelos {
        /* margin-left: 5rem !important; */
    }
    /* .nav-link {
        height: 3.9rem;
    } */
    .vw-side-menu-content[data-level='2'] a label.menu-head--title{
        /* max-width:90px; */
        top:2rem;
        left:0;
        position: absolute;
        margin-left:1rem !important;
    }
}


/* Ipad */

@media (max-width: 768px) {
    .title1 {
        display: block;
    }
    .title2 {
        display: block;
    }
    .menu-head--title {
        font-weight: bold;
        color: #2F3538;
        font-size: 19px;
    }
    .vw-side-menu-content {
        width: 329px;
    }
    .vw-side-menu-content a {
        width: -309px !important;
        font-size: 17px !important;
        left: 60px !important;
        padding-bottom: 0px;
        padding-top: 0px;
        margin-bottom: -5px;
    }
    .vw-side-menu-content a:before {
        width: 389px !important;
        font-size: 17px !important;
    }
    .vw-side-menu img {
        display: none;
    }
    .vw-side-menu-content[data-level='2'].show,
    .vw-side-menu-content[data-level='3'].show {
        transition: 0.5s;
        left: 0px !important;
        width: 321px !important;
        top: 0px !important;
    }
    .vw-side-menu-content[data-level='3'].show {
        width: 312px !important;
    }
    .vw-side-menu-content[data-level='2'].hide,
    .vw-side-menu-content[data-level='3'].hide {
        transition: 0.5s;
        left: -400px !important;
        width: 321px !important;
        top: 0px !important;
    }
    .is-arrow:before {
        margin-top: -13px;
        margin-left: -10px;
        position: absolute;
    }
    .vw-side-menu-content[data-level='2'] .nav-align,
    .vw-side-menu-content[data-level='3'] .nav-align {
        margin-left: 45px;
        height: 100%;
        align-items: center;  
    }
    .menu-head--title:before {
        font-family: "VW Icons";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 32px !important;
        margin-top: -3.5px;
        margin-left: 0px;
        content: "\E92D";
        position: absolute;
        left: -35px;
        color: #808890;
        /* font-weight: bold; */
    }
    .txt-modelos {
        margin-left: -48px;
    }
    .nav-link {
        height: 3.9rem;
    }
    .menu-head--title{
        top:2rem;
        position: absolute;
    }
}


/* Phone 6, 7, 8, X */

@media (max-width: 375px) {
    .title2 {
        display: block;
    }
    .vw-side-menu-content {
        width: 376px;
    }
    .menu-head--title {
        font-weight: bold;
        color: #2F3538;
        font-size: 19px;
    }
    .vw-side-menu-content a {
        font-size: 16px !important;
        padding-bottom: 0px;
        padding-top: 0px;
        margin-bottom: -5px;
        margin-left: 10px;
        margin-right: 20px;
    }
    .vw-side-menu-content a:before {
        width: 376px !important;
        font-size: 16px !important;
    }
    .vw-side-menu img {
        display: none;
    }
    
    .vw-side-menu-content[data-level='2'].show,
    .vw-side-menu-content[data-level='3'].show {
        transition: 0.5s;
        left: 0px !important;
        width: 366px !important;
        top: 0px !important;
    }
    .vw-side-menu-content[data-level='3'].show {
        width: 356px !important;
    }
    .vw-side-menu-content[data-level='2'].hide,
    .vw-side-menu-content[data-level='3'].hide {
        transition: 0.5s;
        left: -400px !important;
        width: 370px !important;
        top: 0px !important;
    }
    
    .vw-side-menu-content[data-level='3'] .nav-link {
        min-height: 5rem;
        height: auto;
    }
    .vw-side-menu-content[data-level='3'] .nav-align {
        min-height: 5rem;
    }
    .is-arrow:before {
        margin-top: -15px;
        margin-left: -10px;
        position: absolute;
    }
    
    .menu-head--title:before {
        font-family: "VW Icons";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        margin-top: -3.5px;
        margin-left: 0px;
        content: "\E92D";
        position: absolute;
        left: -35px;
        color: #808890;
        /* font-weight: bold; */
    }
   
}


/* Phone 5 */

@media (max-width: 320px) {
    .vw-side-menu-content {
        width: 320px;
    }
    #title2 {
        display: block;
    }
    .menu-head--title {
        font-weight: bold;
        color: #2F3538;
        font-size: 19px;
    }
    .vw-side-menu-content[data-level='2'].show,
    .vw-side-menu-content[data-level='3'].show {
        transition: 0.5s;
        left: 0px !important;
        width: 310px !important;
        top: 0px !important;
    }
    .vw-side-menu-content[data-level='3'].show {
        width: 300px !important;
    }
    .vw-side-menu-content[data-level='2'].hide,
    .vw-side-menu-content[data-level='3'].hide {
        transition: 0.5s;
        left: -400px !important;
        width: 310px !important;
        top: 0px !important;
    }
    
    .nav-link {
        height: 3.9rem;
        /* width: 20rem; */
        padding: 0px;
    }
    
    .menu-head--title:before {
        font-family: "VW Icons";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 22px;
        margin-top: -3.5px;
        margin-left: 0px;
        content: "\E92D";
        position: absolute;
        left: -35px;
        color: #808890;
        /* font-weight: bold; */
    }
   
}


@media (max-height: 620px) and (min-width: 992px){
    .vw-side-menu-content[data-level='1'] a {
        padding-top: 2vh;
        padding-bottom: 2vh;
    }
    .vw-side-menu-content li.nav-item:first-child a.nav-link{
        min-height: 102px;
    }
    .vw-side-menu-content .nav-link .brand-icon {
        display: none;
    }

    /** IE 11 HACK */
    .vw-side-menu-content[data-level='1'] {
        /* overflow-y: scroll; */
        /* overflow: hidden; */
        width: 100% !important;
        height: 100%;
    }
    _:-ms-fullscreen, :root .vw-side-menu-content[data-level='1'] {
        overflow: visible;
    }
}

@media (max-height: 510px) and (min-width: 992px){
    .vw-side-menu-content[data-level='1'] {
        /* overflow-y: scroll; */
        /* overflow: hidden; */
        -ms-overflow: auto;
        width: 100% !important;
        height: 100%;
    }
    _:-ms-fullscreen, :root .vw-side-menu-content[data-level='1'] {
        overflow: visible;
    }
    .vw-side-menu-content[data-level='1'] > .nav {
        /* overflow-x: visible; */
        overflow: auto;
        width: calc(100% + 20px) !important;
        /* margin-right: 20px; */
        height: 100% !important;
    }
    .vw-side-menu-content[data-level='1'] .nav-item:hover {
        /* min-width: 115px; */
        width: 100% !important;
        /* overflow-x: visible; */
    }
    .vw-side-menu-content[data-level='1'] > .nav > .nav-item {
        padding-right: 15px;
        margin-right: 5px;
    } 
    
    .vw-side-menu-content[data-level='1'] > .nav > .nav-item > a {
        max-width: calc(100% + 20px) !important;
    }  
    .vw-side-menu-content[data-level='1'] > .nav > .nav-item > a:hover label {
        /* max-width: 100%; */
        /* margin-right: 20px */
    }  

    .vw-side-menu-content[data-level='1'] .nav-item:hover .vw-side-menu-content[data-level='2'] {
        display: inline-block;
        left: 114px;
        top: 0px;
        width: 300px;
        position: fixed;
        z-index: 40;
        overflow: hidden;
    }
    .vw-side-menu-content[data-level='2'],
    .vw-side-menu-content[data-level='3'] {
       left:-430px;
    }
    .vw-side-menu-content[data-level='2'] .nav,
    .vw-side-menu-content[data-level='3'] .nav {
        height: 100%;
    }
    .vw-side-menu-content[data-level='2'] .nav-item:hover .vw-side-menu-content[data-level='3'] {
        left: 412px;
    }
    ::-webkit-scrollbar        {
        z-index: 10;
        width: 5px;
    }
}