.vw-fileuploader .file-upload-wrapper:not(.input-field) .filepond--wrapper {
  min-height: 150px;
  /* position: relative !important; */
}
.vw-fileuploader .file-upload-wrapper.input-field {
 max-width: 100%;
 position: relative;
}
.vw-fileuploader .filepond--root.filepond--hopper > div:not(.filepond--list-scroller) {
  display:none;
}
.vw-fileuploader .input-field .filepond--root.filepond--hopper > div:not(.filepond--drop-label){
  opacity: 0;
  max-height:2px
}
.vw-fileuploader .input-field .filepond--root.filepond--hopper > div.filepond--list-scroller .filepond--item {
  max-height: 20px;
}
.vw-fileuploader .filepond--drop-label {
  height: 160px;
  width:18.66666%;
  max-width:175px;
  opacity: 0 !important;
  z-index: 7;
  position: absolute;
  top:0;
  left: 0;
}
  .vw-fileuploader .input-field .filepond--root.filepond--hopper > .filepond--drop-label {
    display:block;
    width:100%;
    max-width:100%;
    height:50px;
  }
.vw-fileuploader .filepond--browser.filepond--browser{
  width:17.66666%;
  max-width:175px;
  height: 150px !important;
  z-index: 10;;
}
  .vw-fileuploader .input-field .filepond--root.filepond--hopper > .filepond--browser.filepond--browser {
    display:block;
    width:100%;
    max-width:100%;
    height:50px !important;
  }
.vw-fileuploader .filepond--panel > div {
  background-color:transparent ;
}

.vw-fileuploader .filepond--list-scroller {
  /* transform: translate3d(0px, 0, 0) !important; */
  margin-top: 0 !important;
  top:0;
  left: calc(17% + .5rem);
  /* position: relative !important; */
}
.vw-fileuploader .filepond--list {
  width: 100%;
  left:-.3rem;
  /* position: relative !important; */
  /* left:20% !important; */
}
.vw-fileuploader .filepond--item {
  width:21%;
  max-width:21%;
  height: 148px !important;
  border: dashed 3px #e3e5e6 !important;
  /* margin-right: .5rem; */
  margin-bottom: 1rem;
  /* display: inline-block; */
  /* position: relative; */
  /* left: 0; */
  /* transform: translate3d(0px, 0, 0) scale3d(1, 1, 1) !important; */
}
.vw-fileuploader .filepond--list .filepond--item:first-child { 
  /* margin-left: calc(20% + .5rem); */
}
.vw-fileuploader .filepond--image-preview-wrapper {
  border-radius: 0;
}
.vw-fileuploader  .filepond--image-preview-overlay-success {
  opacity: 0 !important;
}

.vw-fileuploader .file-upload-button{
  width:15.66666%;
  max-width:175px;
  height: 147px !important;
  background-color:transparent ;
  border: solid 2px #e3e5e6 !important;
  display: block;
  position: absolute;
}

.vw-fileuploader .imgBoard {
  position: absolute;
  left: 50%;
  top: 3%;
  margin-left: -20px;
  width: 40px !important;
  height: 40px !important;
  max-width: 40px !important;
  max-height: 40px !important;
}
.vw-fileuploader .input-field .imgBoard {
  margin-left: -35px;
}
.vw-fileuploader .file-upload-text {
  width: 100%;
  text-align: center;
  color:#0199da;
  position: absolute;
  left: 0;
  top: 60%;
}

  .vw-fileuploader .input-field .file-upload-button{
    width:100%;
    max-width:100%;
    height: 52px!important;
  }

  .vw-fileuploader .input-field  .imgBoard {
    width: 38px;
    max-width: 38px;
    height:auto;
    position: absolute;
    left: calc(100% - 8px);
    top:4px;
    margin-top: 0;
  }
  
  .vw-fileuploader .detail-text {
    opacity: 0;
  }
  .vw-fileuploader .input-field  .file-upload-text,
  .vw-fileuploader .input-field .detail-text {
    width: 100%;
    text-align: left;
    position: absolute;
    left: 10px;
    top: 25%;
    font-size: 1.4rem;
    line-height: 1.5;
    color: #666;
    opacity: 1;
  }


  .vw-fileuploader.input-filled  .input-field .file-upload-text {
    font-size: 1rem;
    color: #909090;
    top:3px;
  }

  .vw-fileuploader  .input-field .detail-text {
    display: none;
    font-size: 1.3rem;
    color:#000;
  }
  .vw-fileuploader.input-filled  .input-field .detail-text {
    display: block;
    top: 42%;
  }

@media (max-width: 544px) {
  .vw-fileuploader .filepond--drop-label,
  .vw-fileuploader .filepond--panel[data-scalable=true]:not(.filepond--item-panel) {
      width:28.66666%;
      max-width:28.66666%;
  }

  .vw-fileuploader .filepond--item {
      width:32.66666%;
      max-width:32.66666%;
  }

  .vw-fileuploader .filepond--drop-label {
      height: 140px;
  }
  .vw-fileuploader .filepond--panel[data-scalable=true]:not(.filepond--item-panel) {
      height: 130px !important;
  }
  
  .vw-fileuploader .filepond--item {
      height: 148px !important;
  }

  .vw-fileuploader .filepond--list-scroller {
    left: calc(30% + .5rem);
  }
  .vw-fileuploader .filepond--item {
    width:48%;
    max-width:48%;
  }
  .vw-fileuploader .file-upload-button{
    width:28%;
    max-width:28%;
  }

  .vw-fileuploader .imgBoard {
     width: 40px !important;
     height: 40px !important;
     max-width: 40px !important;
     max-height: 40px !important;
     left:50%;
  }
  
  .vw-fileuploader .file-upload-wrapper.input-field {
    margin-top: 2rem
   }

  .vw-fileuploader .file-upload-text {
     font-size: 1.3rem;
     top:50%;
  }
}