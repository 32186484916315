.nextArrow, .prevArrow{
    width: 150%;
    height:8rem !important;
    /* background: rgba(3, 3, 3, 0.397); */
    margin-top: -2.5rem;
  }
  .prevArrow img{
   margin-top: 2.5rem;
  }
  .nextArrow img{
    margin-top: 2.5rem;
    margin-left: 1rem;
  }

  .nextArrow{
    width: 150%;
    height:8rem !important;
    /* background: rgba(3, 3, 3, 0.397); */
    margin-top: -2.8rem;
  }

.Narrow{
    /* left: -1rem; */
    margin-left: 2rem;
}
.Parrow{
    margin-left: 0.5rem;
}
@media(max-width:1920px){

    .nextArrow{
        width: 4rem;
        height:8rem !important;
        /* background: rgba(3, 3, 3, 0.397); */
        margin-top: -2.8rem;
      }
      .nextArrow img{
        margin-top: 2.5rem;
        margin-left: 1rem;
      }
   
}
@media(max-width:1600px){
    .nextArrow{
        width: 4rem;
        height:8rem !important;
        /* background: rgba(3, 3, 3, 0.397); */
        margin-top: -2.5rem;
      }
      .nextArrow img{
        margin-top: 2.5rem;
        margin-left: 1rem;
      }
}
@media(max-width:1500px){
    .nextArrow{
        width: 4rem;
        height:8rem !important;
        /* background: rgba(3, 3, 3, 0.397); */
        margin-top: -2.5rem;
      }
      .nextArrow img{
        margin-top: 2.5rem;
        margin-left: 1.6rem;
      }
}
@media(max-width:1366px){
    .nextArrow{
        width: 4rem;
        height:8rem !important;
        /* background: rgba(3, 3, 3, 0.397); */
        margin-top: -2.5rem;
      }
      .nextArrow img{
        margin-top: 2.5rem;
        margin-left: 1.5rem;
      }
}