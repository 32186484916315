.newscomponent-item {
  padding-right:0;
}
.isSlider .newscomponent-item .col-image {
  border-radius: 5px;
  overflow: hidden;
}
.isSlider .newscomponent-item .col-image,
.isSlider .newscomponent-item .col-data {
  display: block;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.isVertical .newscomponent-item {
  margin-bottom: 2vw;
}
 .newsdate-component-before,
.newsdate-component-after {
  display: none;
}
.isVertical .newscomponent-item .col-data .vw-date-style {
  padding-bottom:0.5rem;
 }

.isVertical .newscomponent-item .col-data .newscomponenttitle .title {
 font-size: 2.4vw;
 line-height: 1.2;
}
.isSlider .newscomponent-item .col-data .newscomponenttitle .title {
  font-size: 1.8vw;
  line-height: 1.2;
 }

.newsItem-container {
  position: relative;
}

.container .newsItem-container {
  width: 100%;
}
.container .newsItem-container > div {
  width:100%;
  max-width: 100%;
}
.container-fluid .newsItem-container {
  width: 100%;
  padding-left: 2rem;
} 

.container-fluid .newscomponent-item {
  padding-left:0rem;
  width: calc(100% - 10%);
}

.newscomponenttitle .title {
  font-family: VWHeadOffice-Bold;
}
  
  .isVertical .newsbody-split {
    display: block;
    width: 100%
  }
  .newsbody-split-txt {
    line-height: 1.5em;
    font-size: 1.3rem;
    color: #666666;
  }

  /* .newscomponent-item .row{
    margin-left: 1.2rem;
  } */

  /* .container-fluid .slick-arrow img{
    margin-left: 2rem;

  } */
   /* .container-fluid .slick-arrow{
    margin-left: 2rem;
  } */

@media(min-width:1679px){
  .newscomponent-item .row{
    margin-left: 1.1rem;
  }
  .newsItem-container .NextA{
   left: -2.1rem;
  }
}

@media (min-width: 768px) { 
  .isVertical .newsdate-component-after {
    display: block;
  }
  .isSlider .newsdate-component-before {
    display: block;
  }
}

@media (max-width: 767px) { 
  .newscomponent-item .col-data .newscomponenttitle .title {
    font-size: 2.4rem !important;
    line-height: 2.5rem;
  }
  .newscomponent-item  {
    text-align: left;
  }
  .newsdate-component-before {
    display: block;
  }
}

@media (max-width: 991px) { 
  .container-fluid .newsItem-container {
    width: calc(100vw - 2rem);
    padding-left: .5rem;
  }
  .isVertical .newscomponent-item {
    margin-right: -3rem;
  }
  .newscomponent-item .col-data .newscomponenttitle .title {
    font-size: 1.7rem !important;
  }
  .vw-read-more {
    padding-top: 1rem;
  }
}

@media (min-width: 992px) { 
  .newscomponent-item .col-data .newscomponenttitle .title {
    font-size: 1.9rem !important;
  }
  .isVertical .newscomponent-item .col-data .vw-date-style {
    padding-bottom:1rem;
   }
  .vw-read-more {
    padding-top: 2rem;
  }
}


@media (min-width: 1200px) { 
  .newscomponent-item .col-data .newscomponenttitle .title {
    font-size: 1.8vw;
  }
}