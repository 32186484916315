.center-align{
    top:0.5rem;
}
.icondownloadVersion{
    background: url('./img/bt-download-normal.svg') !important;
    background-repeat: no-repeat !important;
    /* width:48px;
    height: 48px; */
    border-radius: 50%;
   background: #0099d7;
   color: #fff !important;
   padding: 10.5px 11px 10px 10px;
   border: none !important;
    z-index: 100 !important ;
    position: relative;
    background-size: 100% !important;
}

.icondownloadVersion:hover{
    background: url('./img/bt-download-hover.svg') !important;
    background-repeat: no-repeat !important;
    /* width:48px;
    height: 48px; */
    border-radius: 50%;
   background: #0099d7;
   color: #fff !important;
   padding: 10.5px 11px 10px 10px;
   border: none !important;
    z-index: 100 !important ;
    position: relative;
    background-size: 100% !important;
}
.icondownloadVersion:active{
    background: url('./img/bt-download-hover.svg') !important;
    background-repeat: no-repeat !important;
    /* width:48px;
    height: 48px; */
    border-radius: 50%;
   background: #0099d7;
   color: #fff !important;
   padding: 10.5px 11px 10px 10px;
   border: none !important;
    z-index: 100 !important ;
    position: relative;
    background-size: 100% !important;
}

@media(max-width: 576px){
    
    .newsButtons{
        padding-right:6px;
    }

}