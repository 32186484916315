
.btn-primary{
    background: #0199da;
    min-width: 130px;
    width:auto;
    height: 4.2rem;
    line-height: 4.2rem;
    border-radius: 28px;
    padding:0 1.5rem !important;
    margin-left:calc(-4.2rem + 0.5rem);
    text-align: center;
    display: inline;
    border:none;
    cursor: pointer;
  }
  .btn-primary:focus {
    box-shadow: none;
      border: #0199da;
      background: #0199da;
      outline: none;
  }
  .btn-primary:active,
  .btn-primary:hover {
    box-shadow: none;
    background-color: #0075a7;
    border-color: #00436d;
    outline: none;
  }
  .container:not(.container-fluid) .btn-primary {
    margin-left:1rem;
  }
  .btn-primary .btn-txt{
    font-family:VWTextOffice, sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    /* margin-top: 1.5rem; */
    margin-left: 0.5rem;
    color: #fff;
    /* -webkit-font-smoothing: antialiased; */
    display: inline-block;
    vertical-align: top;
  }
  
  .btn-primary .is-show-more{
    display: inline-block;
    top:0rem;
    font-size: 2.3rem;
    margin:0;


    position: relative;
    z-index: 3;
    color: #fff;
  }
  
  .whiteBG .btn-primary{
    background: #0199da!important;
  }
  .whiteBG .btn-primary .btn-txt{
    color: #fff!important;
  }
  .whiteBG .btn-primary .is-show-more{
    color: #fff!important;
  }
  
  .btn-white {
      background: #fff !important;
  }
  .btn-white:focus,
  .btn-white:hover {
    box-shadow: none;
      border: none;
      background: #fff;
      outline: none;
  }
  .btn-white .btn-txt{
    color: #0199da;
  }
  
  .btn-white .is-show-more{
      color: #0199da;
  }
  .whiteBG .btn-white {
    background: #0199da!important; 
  }
  .whiteBG .btn-white .btn-txt{
    color: #fff!important;
  }
  .whiteBG .btn-white .is-show-more{
    color: #fff!important;
  }
 

.btn-primary .arrow-foward,
.list-group button .arrow-foward {
  display: inline-block;
    top: 0rem;
    font-size: 2.3rem;
    line-height: .6;
    margin: 0;
    position: relative;
    z-index: 3;
    color: #fff;
  }

  .btn-no-icon .arrow-foward:before,
  .btn-primary .arrow-foward:before,
  .list-group button .arrow-foward:before {
    content: "";
    padding-right:5px;
    padding-left:20px;
    background: url('./icons/icone-botao-setinha-branco.svg') center center no-repeat;
}

.btn-primary .arrow-foward-blue,
.list-group button .arrow-foward {
  display: inline-block;
    top: 0rem;
    font-size: 2.3rem;
    line-height: .6;
    margin: 0;
    position: relative;
    z-index: 3;
    color: #fff;
  }

  .btn-no-icon .arrow-foward-blue:before,
  .btn-primary .arrow-foward-blue:before,
  .list-group button .arrow-foward:before {
    content: "";
    padding-right:5px;
    padding-left:20px;
    background: url('./icons/icone-botao-setinha-azul.svg') center center no-repeat;
}