
.banner-container {
  margin-bottom: 0;
  min-height: calc(100vw / 1080);
}
_:-ms-fullscreen, :root .banner-container {
  min-width: calc(1920 * 100vh);
  max-width: 100%;
}
.banner-container .holder-image,
.banner-container .holder-image img {
  min-height: calc(100vw / 1080);
}
.bg{
  height:100%;
  width:100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));;
  bottom:0%;
  left:0%;
  position: absolute;
}
.banner-container .slick-slider,
.banner-container .slick-list  {
  width:calc(100vw - 9rem);
  height: 100%;
  max-height: 100%;
  min-height: calc(100vw / 1080);
  max-width: 100%;
  margin-left: 0;
  overflow: hidden;
  position: relative;
  z-index: 10;
}
.banner-container .slick-slide{
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  min-height: calc(100vw / 1080);
 }

.banner-container .slick-slider .slick-slide {
  background:transparent;
}
.banner-container .slick-slider .slick-slide > div {
  position: relative;
  max-height: 100%;
}

.banner-container .slick-prev {
  left:2rem;
  z-index: 20;
}
.banner-container .slick-next {
  right:3rem
}
.banner-container .slick-dots {
  text-align: center;
  margin-top: -5rem;
  padding-left: 0;
  position: relative;
  z-index: 20;
}
.banner-container .slick-dots li button{
  background: #fff;
}
.banner-container .slick-dots li.slick-active button {
  background: #fff
}

.banner-container .slick-slider .slick-slide .legend {
  background:transparent;
  color: #fff;
  opacity: 2;
  text-align: left;
  font-family: VWHeadOffice;
  top:11%;
  left:5%;
  position: absolute;
  width: 50%;
  z-index: 20;
}

.banner-container .slick-slider .slick-slide .legend .legend-title {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 1.1;
  display: block;
  margin-bottom:2rem;
}

.banner-container .slick-slider .slick-slide .legend .legend-subtitle {
  font-size: 1.6rem;
  font-weight: 100;
  line-height: 1.2;
  display: block;
  -webkit-font-smoothing: antialiased;
  width: 88%;
 
}
/* .banner-container .nextArrow, .banner-container .prevArrow{
  width: 130%;
  background: rgba(3, 3, 3, 0.397);
} */

/* responsive step 1 */
@media (min-width: 993px) and (max-width: 1200px) {
  
}
/* responsive step 1 */
@media (min-width: 767px) and (max-width: 992px) {
  .banner-container .slick-slider,
  .banner-container .slick-list  {
    width:calc(100% - 0.1%);
  }
  
  .banner-container .slick-slider .slick-slide .legend {
    /* margin-top:-150px; */
    width: 70%;
  }
  .banner-container .slick-slider .slick-slide .legend .legend-title {
    font-size: 4.2rem;
  }
  .banner-container .slick-slider .slick-slide .legend .legend-subtitle {
    font-size: 1.8rem;
  }
}

/* responsive step 2 */
@media (min-width: 544px) and (max-width: 768px) {
  .banner-container .slick-slider,
  .banner-container .slick-list  {
    width:calc(100% - 0.1%);
    overflow: visible;
  }
  .banner-container .slick-slide  {
    overflow: visible;
    /* min-height: 30vh; */
  }
  .banner-container {
    margin-bottom: 4vh;
  }
  .bg{
    display: none;
  }
  .banner-container .slick-dots {
    position: absolute;
    top: calc((100vw * 1080) / 1920);
    margin-top:-25px !important;
  }
  .banner-container .slick-arrow {
    top:35%;
  }
  .banner-container .slick-slider .control-dots {
    display:none;
  }
  .banner-container .slick-slider .slick-slide .legend {
    width: 95% !important;
    position: relative;
    bottom:-15px;
    color: #000;
  }
  .banner-container .slick-slider .slick-slide .legend .legend-title {
    font-size: 5.5vw;
  }
  .banner-container .slick-slider .slick-slide .legend .legend-subtitle {
    font-size: 2.5vw;
  }
}

/* responsive step 3 */
@media (max-width: 544px) {
  .banner-container {
    margin-bottom: 0;
    min-height: calc(120vw / 1080) !important; 
  }
  .banner-container .slick-slider,
  .banner-container .slick-list{
    width: 100vw;
    height: auto;
    /* max-height: 50vh; */
    /* height: calc(100vw / 1080) !important;  */
  }
  .banner-container .slick-slide  {
    min-height: 30vh !important; 
    
   }
   
  .bg{
    display: none;
  }
  .banner-container .slick-slider .control-dots {
    display:none;
  }
  .banner-container .slick-dots {
    position: absolute;
    top: calc((100vw * 1080) / 1920);
    margin-top:-25px;
  }
  .banner-container .slick-arrow {
    top:25%;
  }
  .banner-container .slick-slider .slick-slide .legend {
    width: 90% !important;
    position: relative;
    bottom:-15px;
    color: #000;
  }
  .banner-container .slick-slider .slick-slide .legend .legend-title {
    font-size: 5vw;
    margin-bottom: 1rem;
  }
  .banner-container .slick-slider .slick-slide .legend .legend-subtitle {
    font-size: 2.8vw;
    padding-bottom: 2rem;
  }
}

