.newscomponent-item {
  padding-right:0;
}
.isSlider .newscomponent-item .col-image,
.isSlider .newscomponent-item .col-data {
  display: block;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.isVertical .newscomponent-item {
  margin-bottom: 2vw;
}
 .newsdate-component-before,
.newsdate-component-after {
  display: none;
}
.isVertical .newscomponent-item .col-data .vw-date-style {
  padding-bottom:0.5rem;
 }

.isVertical .newscomponent-item .col-data .newscomponenttitle .title {
 font-size: 2.4vw;
 line-height: 1.2;
}
.isSlider .newscomponent-item .col-data .newscomponenttitle .title {
  font-size: 1.8vw;
  line-height: 1.2;
 }

.newsItem-container {
  width: calc(100vw - 8%);
  position: relative;
  /* padding-left: 1.5rem; */
  /* margin-left: 2%; */
}

.container .newsItem-container {
  width: 100%;
}
.container-fluid .newsItem-container {
  width: calc(100vw - 8%);
}
.container-fluid .newsItem-container {
  width: calc(100vw - 12rem);
  padding-left: 1.5rem;
}

.container-fluid .newscomponent-item {
  padding-left:0;
  width: calc(100% - 8%);
}

/* .newsItem-container .slick-slider,
.newsItem-container .slick-list  {
  width:calc(100% + 18px);
} 
.newsItem-container .slick-slide{
  display: inline-block;
  vertical-align: top;
 }
*/
.newscomponenttitle .title {
  font-family: VWHeadOffice-Bold;
}

/* .container-fluid .newsItem-container .slick-list  {
  width:calc(100% - 15px);
} */

.isVertical .newsItem-container .slick-list  {
  width:100%;
}
  
  .isVertical .newsbody-split {
    display: block;
    width: 100%
  }
  .newsbody-split-txt {
    line-height: 1.5em;
    font-size: 1.3rem;
    color: #666666;
  }

  .vw-social-twitter .newsbody-split,
  .vw-social-twitter .newsbody-split-txt {
    line-height: 1.5em;
    font-size: 1.3rem;
    color: #666666;
    height: 55px;
  }
  .vw-social-twitter .newsbody-split img {
    display: none;
}
/* .btn-row-Social{
  background: #0199da;
  width: 220px;
  height: 4.3rem;
  line-height: 4rem;
  border-radius: 28px;
  text-align: center; */
  /* margin-left:39rem !important;
  transform: translate(100%, -20%); */
  /* margin-left:calc(48vw - 160px);
} */
/* .btn-row-Social .btn-Social{
background: #0199da;
width: 220px;
height: 4.3rem;
line-height: 4rem;
border-radius: 28px;
margin-left: -20rem; */
/* text-align: center !important;
align-items: center !important;
justify-content: center !important;
align-content: center !important; */
/* } */
/* .btn-row-Social .btn-Social-txt{
  font-family:VWTextOffice, sans-serif;
font-size: 1.3rem;
font-weight: 600;
margin-top: 3.5px;
margin-left: -0.2rem;
color: #fff;
background: #0199da;
-webkit-font-smoothing: antialiased;
display: inline-block;
vertical-align: top;
} */

.img-twitter{
  position: relative !important;
}
.img-twitter .cropped-image img {
  max-width: 3.8vw;
 }
.vw-social-twitter{
  /* margin-left: 4rem; */
}
.vw-social-twitter .cropped-image{
  position: relative !important;
  height: 9.7rem;
  width: 5rem;
}
.vw-social-twitter .newsbody-split {
  overflow: hidden !important
}
.margin-icon{
  margin-left: -9rem;
}

.icons-sociais img{
  width: 3rem;
}
.margin-icon-1{
  margin-left: -1.5rem;
}
.vw-social-twitter .txt-twitter{
  margin-left:-5.5rem !important;
}
.tags-txt-social{
  text-align: left ;
}
/* .social-container{
  margin-left: -1.9rem;
} */
.margin-icon{
  margin-left: -16rem !important;
}



@media (min-width: 1200px) { 
  .newscomponent-item .col-data .newscomponenttitle .title {
    font-size: 1.8vw;
  }
  .container-fluid .newsItem-container + .btn-Gallery {
    margin-left: calc(50vw - 170px);  
  }
  .btn-row-Social{
    margin-left:calc(48vw - 160px);
  }
  .vw-social-twitter .txt-twitter{
    margin-left:-4.5rem ;
  }
  .margin-icon{
    margin-left: -14rem ;
  }

}


@media (min-width: 768px) { 
  
  .isVertical .newsdate-component-after {
    display: block;
  }

  .isSlider .newsdate-component-before {
    display: block;
  }
  .tags-txt-social{
    text-align: left ;
  }
}

@media (max-width: 767px) { 
  .newscomponent-item .col-data .newscomponenttitle .title {
    font-size: 2.4rem !important;
    line-height: 2.5rem;
  }
  .newscomponent-item .slick-track  {
    text-align: center;
  }
  .newscomponent-item .slick-track  .slick-slide {
    max-width: 80vw;
  }
  .newscomponent-item  {
    text-align: left;
  }
  .newsdate-component-before {
    display: block;
  }
  .tags-txt-social{
    text-align: left ;
  }
  .img-twitter + .col-10 {
   text-align: left !important;
   }
   .img-twitter .cropped-image img {
    max-width: 38px;
   }
   .vw-social-twitter .newsbody-split {
     margin-left: -1rem;
   }
}
@media (max-width: 991px) { 
  .container-fluid .newsItem-container {
    width: calc(100vw - 2.2rem);
    padding-left: .5rem;
  }
  .newscomponent-item .col-data .newscomponenttitle .title {
    font-size: 1.7rem !important;
  }
  /* .container-fluid .newsItem-container .slick-dots {
    margin-left: -1.5vw;
  } */
  .vw-read-more {
    padding-top: 1rem;
  }
  
  .vw-social-twitter .icons-sociais .cropped-image{
    height: 4.7rem;
  }
}

@media (min-width: 992px) { 
  .newscomponent-item .col-data .newscomponenttitle .title {
    font-size: 1.9rem !important;
  }
  .isVertical .newscomponent-item .col-data .vw-date-style {
    padding-bottom:1rem;
   }
  .vw-read-more {
    padding-top: 2rem;
  }
  .container-fluid .newsItem-container + .btn-Gallery {
    margin-left: calc(50vw - 160px);  
  }

}

@media (max-width: 2560px) { 
  .vw-social-twitter .txt-twitter{
    margin-left:-12.5rem !important;
  }
  .margin-icon{
    margin-left: -29.5rem !important;
  }
}
@media (max-width: 2000px) { 
  .vw-social-twitter .txt-twitter{
    margin-left:-6.5rem !important;
  }
  .margin-icon{
    margin-left: -19.5rem !important;
  }
}
@media (max-width: 1900px) { 
  .vw-social-twitter .txt-twitter{
    margin-left:-5.5rem !important;
  }
  .margin-icon{
    margin-left: -18.5rem !important;
  }
}
@media (max-width: 1800px) { 
  .vw-social-twitter .txt-twitter{
    margin-left:-5.5rem !important;
  }
  .margin-icon{
    margin-left: -17.5rem !important;
  }
}
@media (max-width: 1700px) { 
  .vw-social-twitter .txt-twitter{
    margin-left:-5.5rem !important;
  }
  .margin-icon{
    margin-left: -15.5rem !important;
  }
}
@media (max-width: 1679px) { 
  .vw-social-twitter .txt-twitter{
    margin-left:-5.5rem !important;
  }
  .margin-icon{
    margin-left: -15.5rem !important;
  }
}
@media (max-width: 1600px) { 
  .vw-social-twitter .txt-twitter{
    margin-left:-5.5rem !important;
  }
  .margin-icon{
    margin-left: -15.5rem !important;
  }
}
@media (max-width: 1440px) { 
  .btn-row-Social{
    margin-left:calc(48vw - 160px);
  }
  .vw-social-twitter .txt-twitter{
    margin-left:-3.5rem !important;
  }
  .margin-icon{
    margin-left: -13.5rem !important;
  }

}
@media (max-width: 1244px) { 
  .btn-row-Social{
    margin-left:calc(48vw - 160px);
  }
  .vw-social-twitter .txt-twitter{
    margin-left:-3.5rem !important;
  }
  .margin-icon{
    margin-left: -13.5rem !important;
  }

}

@media(max-width:1419px){
  .vw-social-twitter .txt-twitter{
    margin-left:-3.3rem !important;
  }
  .margin-icon{
    margin-left: -13rem !important;
  }
}
/* @media(max-width:1366px){
  .container-fluid .slick-dots {
    width: calc(100% - 1rem) ;
}
} */
@media(max-width:1336px){
  .vw-social-twitter .txt-twitter{
    margin-left:-2.3rem !important;
  }
  .margin-icon{
    margin-left: -11rem !important;
  }
}
@media(max-width:1262px){
  .vw-social-twitter .txt-twitter{
    margin-left:-1.5rem !important;
  }
  .margin-icon{
    margin-left: -11rem !important;
  }
}

@media(max-width:1120px){
  .vw-social-twitter .txt-twitter{
    margin-left:-0.3rem !important;
  }
  .margin-icon{
    margin-left: -8rem !important;
  }
  .btn-row-Social{
    margin-left:calc(47vw - 160px);
  }
}

@media(max-width:1024px){
  .vw-social-twitter .txt-twitter{
    margin-left:-0.3rem !important;
  }
  .margin-icon{
    margin-left: -8rem !important;
  }
  .btn-row-Social{
    margin-left:calc(47vw - 160px);
  }
}
@media(max-width:992px){
  .btn-row-Social{
    margin-left:calc(47vw - 160px);
  }
}
@media(max-width:960px){
  .btn-row-Social{
    margin-left:calc(52vw - 160px);
  }
}

@media(max-width:768px){
  .vw-social-twitter .txt-twitter{
    margin-left:-0.1rem !important;
    text-align: justify;
  }
  .margin-icon{
    margin-left: -4.5rem !important;
  }
  /* .btn-row-Social{
    margin-left:calc(52.4vw - 160px);
  } */
}
@media(max-width:761px){
  .vw-social-twitter .txt-twitter{
    margin-left:-4rem !important;
    text-align: justify;
  }
  .margin-icon{
    margin-left: -15rem !important;
  }
}
@media(max-width:700px){

  .vw-social-twitter .txt-twitter{
    /* margin-left:-0.1rem !important; */
    text-align: justify;
    margin-left:-1.4rem !important;
  }
  .margin-icon{
    margin-left: -10.5rem !important;
  }
}
@media(max-width:600px){

  .vw-social-twitter .txt-twitter{
    /* margin-left:-0.1rem !important; */
    text-align: justify;
    margin-left:-1rem !important;
  }
  .margin-icon{
    margin-left: -9.5rem !important;
  }
}
@media(max-width:595px){
  .vw-social-twitter .txt-twitter{
    margin-left:-1rem !important;
    text-align: justify;
  }
  .margin-icon{
    margin-left: -9.5rem !important;
  }
}

@media(max-width:544px){
  .social-container{
    /* margin-left: -4rem; */
  }
  .vw-social-twitter{
    /* height: 19.7rem; */
  }
  .vw-social-twitter .txt-twitter{
    margin-left:-1rem !important;
    text-align: justify;
  }
  .margin-icon{
    margin-left: -9.5rem !important;
  }
  .tags-txt-social{
    text-align: left;
  }
  .vw-social-twitter .btn-primary{
    /* margin-left: -1rem; */
  }
  .social-container .slick-dots{
    margin-left: 1.9rem;
  }
  .homeMobile .twitter-container .slick-dots {
    margin-left: 5vw !important
  }
  .homeMobile .twitter-container .newsItem-container + a .row .col-12 {
    margin-left: 5vw !important
  }
}
@media(max-width:449px){

  .vw-social-twitter .txt-twitter{
    /* margin-left:-0.1rem !important; */
    text-align: justify;
    left:2.5rem !important;
  }
  .margin-icon{
    margin-left: -5.8rem !important;
  }
}
@media(max-width:480px){

  .vw-social-twitter .txt-twitter{
    left:2.1rem !important;
    text-align: justify;
  }
  .margin-icon{
    margin-left: -6.8rem !important;
  }
}
@media(max-width:425px){

  .vw-social-twitter .txt-twitter{
    left:2.5rem !important;
    text-align: justify;
  }
  .margin-icon{
    margin-left: -5.8rem !important;
  }
}
@media(max-width:375px){
  .vw-social-twitter .txt-twitter{
    /* left:3rem !important; */
  }
  .margin-icon{
    margin-left: -4.8rem !important;
  }


}
@media(max-width:320px){
  .vw-social-twitter .txt-twitter{
    left:4rem !important;
  }
  .margin-icon{
    margin-left: -2.9rem !important;
  }
  .bt-twitter{
    left: 4rem !important;
    position: relative !important;
  }
}