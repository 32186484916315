
.img-icon{
    margin-right: 6px;
    height: 3rem;
    width: 3rem !important;
}
.vw-bodys {
    padding-left: -109px !important;
    background-color: #f7f7f7;
    width: 100%;
}

.columnFooter-1{
    background-color: #f7f7f7;
    margin-bottom: 20rem;
}
.content-footer {
    margin-top: 4rem;
}

.vw-footer {
    margin-top: 20px;   
    margin-bottom: -20rem !important;  
    background-color: #f7f7f7;
}
_:-ms-fullscreen, :root .vw-footer {
    width: 100vw;
}
.container .vw-footer {
    margin:3rem 0 0 -2rem;
}

.container-fluid .vw-footer {
    margin:3rem 0 0 5rem;
    background-color: #f7f7f7;
}

.container-fluid .vw-footer .container-fluid {
    max-width: calc(82vw - 30px) !important;
    margin-left:0;
}


.vw-footer .page-title{
    margin-bottom: 1.5rem;
}
.content-footer2{
    background-color: #f7f7f7;
    margin-top:2.5rem;
}
.content-footer{
    margin-bottom: 18rem;

}
@media (max-width:991px){
    .container .vw-footer {
        margin:3rem 0 0 -11rem;
    }
    .container-fluid .vw-footer {
        margin:3rem 0 0 -7rem;
    }
}