.vw-date-style {
  font-family: "VWTextOffice-Bold";
  font-size: 1.2rem;
  color: #909090;
  padding-top: 10px;
  padding-bottom: 20px;
}

.vw-read-more {
  font-family: "VWTextOffice-Bold";
  font-size: 1.2rem;
  color: #0199da;
  padding-top: 10px;
  padding-bottom: 20px;
}

.vw-read-more .is-read-more {
  margin-left: 0.5rem;
  vertical-align: middle;
  line-height: 1;
}

/* .vw-read-more .is-read-more:before {
 vertical-align: bottom
}  */
.newscomponent-container {
  margin-bottom:3rem;
}
.newscomponent-container .page-title {
  padding-left:1rem;
}
.newscomponent-container .newscomponent-item:hover .newscomponenttitle .title,
.newscomponent-container .newscomponent-item:hover .vw-text-body span {
  color: #0199da;
}

.newscomponent-container .carousel .slide {
  background:#fff
}

.container-fluid .newscomponent-container {
  padding-left: 3.5rem;
}
.container .newscomponent-container .slick-list {
  overflow: hidden
}
.container-fluid .newscomponent-container .slick-list {
  margin-left: 1.3rem;
}
.container .newscomponent-container.isVertical {
  padding-left: 15px;
}

@media (max-width: 992px) {
  .newscomponent-container .page-title {
    padding-left:3rem;
  }
}

@media (max-width: 554px) {
  .container-fluid .newsItem-container {
    margin:0rem;
  }
  .homeMobile .newscomponent-container .page-title {
    padding-left: 1.5rem !important;
  }
  .vw-page .container-fluid .homeMobile .newscomponent-container {
    padding-left: .5rem !important;
    width: 100%;
    margin-bottom: 0;
  }
  .container-fluid .homeMobile .newsItem-container .col-image {
    flex: 0 0 95%;
     max-width: 95%;
  }
}