.container-fluid .car-container {
    padding-left: 3rem;
}

.car-inside {
    width: calc(100vw - 13.5rem);
    padding-left: 1.5rem;
    overflow: hidden;
}

.car-margin {
    margin: 2rem 0 0 0rem;
    position: relative;
}

.vw-car .row:last-child {
    margin-bottom: 4rem;
}

.vw-car .row img {
    margin-top: 2.5rem;
}

.vw-car .row .car-link {
    display: block;
}

.vw-car .row .car-link.car-link-disabled {
    opacity: 0.4;
}
.container-fluid .vw-car .car-margin>.page-title {
    margin-left: 2rem
}

.car-prev {
    margin-left: 2rem;
}
.carSelectRadio {
    z-index: 10;
    background-color: #fff;
}
.carSelectRadio + div {
    display: inline-block;
    z-index: 20;
    margin-left: 1rem;
}
/** INPUT RADIO **/

.inputRadioButton {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    border: 0.03em solid;
    display: inline-block;
    vertical-align: middle;
}

.inputRadioButton:before {
    content: '';
    display: block;
    width: 50%;
    height: 50%;
    margin: 25% 25% 25% 25%;
    border-radius: 25px;
}

.inputRadioButton:checked:before,
.inputRadioButton.isChecked:before {
    background: #0199da;
}

.inputRadioButton:checked+label {
    color: #000000;
}

.inputRadioButton+label {
    color: #666666;
}

/** FIM INPUT RADIO **/

.divInputRadio{
    margin-top: 5px;
    vertical-align: middle;
}

.pageTitle-inline-block{
    margin-left: 10px;
    font-size: 15px !important;
    display: inline-block;
}
@media(min-width:1679px) {
    .car-margin {
        margin: 2rem 0 0 2rem;
        position: relative;
    }
}

@media (max-width: 992px) {
    .car-inside {
        width: calc(100vw - 1.5rem);
        overflow: visible;
    }
    .container-fluid .vw-car .car-margin>.page-title {
        margin-left: 2.5rem
    }
    .car-inside .slick-arrow.slick-prev {
        left: .2rem
    }
    .container-fluid .car-container {
        padding-left: 1.1rem;
    }
    .vw-car .slick-arrow {
        top: 35%;
    }
    .vw-car .slick-next {
        right: .5rem;
    }
}