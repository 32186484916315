.vw-date-style p {
  display: inline-block;
  margin-bottom:0;
}
.vw-date-info {
  width: 40%;
  padding-top:0.5em;
}
.vw-date-style .vw-date-date span {
  display: inline-block;
}

.vw-date-icons {
  width: 59%;
  text-align: right;
}
.vw-date-icons span {
  width:1.7vw;
  height:1.7vw;
  display: inline-block;
  margin:0 0.5em;
}
.vw-date-icons span:last-child {
  margin-right:0;
}
.vw-date-icons .vw-date-icon-fav {
  background: url('./img/vw-icon-favorite.png') bottom center no-repeat;
  background-size: contain;
  background-position: 0px 2px;
  padding-top:0.2rem;
}
.vw-date-icons .vw-date-icon-share {
  background: url('./img/vw-icon-share.png') center center no-repeat;
  background-size: contain;
  width:1.6vw;
  height:1.6vw;
}

.vw-date-style .vw-date-info .vw-date-value {
  padding-right:1rem;
}
.vw-date-style .vw-date-info .vw-date-label {
  padding-left:1rem;
}
