/* APPROVAL DETAIL */
.agent-details-wrapper {
  padding:2rem;
}

.agent-details-wrapper {
  background-color: #f2f2f2;
}
.agent-details-wrapper.events-wrapper {
  background-color: #fff;
}

.white-btn .btn-primary{
  background-color: #fff !important;
  color: #0199da !important;
  margin-top: 1.5rem;
}

.white-btn .btn-primary .btn-txt {
  color: #0199da !important;
}

.white-btn .btn-primary:disabled{
 opacity: .5;
}

.white-btn .btn-primary:disabled .btn-txt {
  color: #ccc !important;
}

.vw-approval-backoffice .manager-approval .loan-date-resume {
  text-align: right;
  display: flex;
  flex-direction: row;
}
.vw-approval-backoffice .manager-approval .loan-date-resume span{
  align-self: flex-start
}

.vw-approval-backoffice .manager-approval .loan-date-resume span:last-child{
  margin-left: auto;
}

.vw-approval-backoffice .manager-approval .btn-primary {
  min-width: 100px;
  margin-left: 0;
  margin-top: 0;
}
.vw-approval-backoffice .manager-approval .btn-primary .btn-txt {
  font-size: 1.2rem !important;
}

.agent-details-wrapper.events-wrapper .events-content {
  background-color: #f2f2f2;
  border-radius: 5px !important;
  box-shadow: 0 1px 1px 0 rgba(215, 215, 215, 0.5) !important;
  border: solid 0.1px #bfc4c8 !important;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.icon-card-date {
  width:24px;
  max-width: 2.1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.details-link {
  font-size: 1.6rem !important;
  color:#0199da !important;
  font-weight: normal !important;
}

.agent-details-wrapper.events-wrapper .loan-number-title {
  font-size: 1.7rem !important;
}
.agent-details-wrapper.events-wrapper .details-link {
  padding-bottom: 0;
  margin-bottom: 1rem;
  border-bottom:0;
  font-size: 1.4rem !important;
}
.agent-details-wrapper.events-wrapper .loan-number-title,
.agent-details-wrapper.events-wrapper .details-link {
  margin-right: -15px;
  margin-left: -15px;
}
.agent-details-wrapper.events-wrapper .events-content .loan-user {
  font-weight: bold;
}

.agent-details-wrapper.events-wrapper .events-content .event-hour-split {
  color: #0199da;
  font-weight: bold;
  font-size: 1.7rem;
  margin-left: 0;
  line-height: 55px;
}

.agent-details-wrapper.agent-details-wrapper.events-wrapper .events-content .col-5{ 
  padding-right: 0;
}
.agent-details-wrapper.agent-details-wrapper.events-wrapper .events-content .icon-card-date {
  margin-bottom: .5rem;
}

.agent-details-wrapper.agent-details-wrapper.events-wrapper .events-content .row-buttons {
  margin-left: 0;
  margin-right:5px;
  margin-top:.5rem;
  margin-bottom:.5rem;
  width:100%;
}
.agent-details-wrapper.agent-details-wrapper.events-wrapper .events-content .row-buttons .col-6{
  padding-right: 0;
  padding-left: 0;
}
.agent-details-wrapper.agent-details-wrapper.events-wrapper .events-content .row-buttons .white-btn .btn-primary {
  margin-top:0 !important;
}

.vw-events .loan-number-title {
  color:#000 !important;
  font-size: 2.2rem !important;
}

.vw-events .btn-primary {
  min-width: 80%;
}

.vw-events .loan-user-title {
  font-size: 1.7rem !important;
  color:#000 !important
}