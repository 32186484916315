.twitter-container  {
  padding:3rem 0;
}

.twitter-container .newsItem-container .slick-next {
 right: 10px;
}
.container .newsItem-container > div {
  width: 100%;
  max-width: 99%;
}
/* @media(max-width:1920px){
  .container-fluid .slick-dots {
    width: calc(100% - 1rem);
    margin-left: 1.2rem;
}
} */
/* @media(max-width:1440px){
  .container-fluid .slick-dots {
    width: calc(100% - 1rem);
    margin-left: 1rem;
}
} */

/* @media(max-width:1366px){
  .container-fluid .slick-dots {
    width: calc(100% - 1rem) ;
    margin-left: 1rem;
}
} */
/* @media(max-width:1100px){
  .container-fluid .slick-dots {
    width: calc(100% - 1rem) ;
    margin-left: -0.9rem;
}
} */
/* @media(max-width:768px){
  .container-fluid .slick-dots {
    width: calc(100% - 1rem) ;
    margin-left: 2rem;
}
} */
@media (max-width: 544px) {
  .twitter-container.isSlider  .slick-list {
    margin-left: 0 !important;
  }

}

@media(max-width:375px){
  .vw-home .newscomponent-container .btn-primary {
    margin-left: 3rem;
}
}
@media(max-width:320px){
  .vw-home .newscomponent-container .btn-primary {
    margin-left: 1rem;
}
}
