.short-image.image{
  position:relative;
  background-color:white
}

.cropped-image{
  display:block;
  position:relative;
  overflow:hidden;
  border-radius: 5px;
}
.cropped-image.no-border {
  border-radius: 0px;
}

.cropped-image img,
.cropped-image video {
  position:absolute;
  top:0;
  left:0%;
  right:auto;
  bottom:auto;
  height:auto;
  width:100%;  
}
.cropped-image--sizer{
  display:block;
  padding-top:100%;
  padding-top:56.25% !important;
}

.vw-gallery-center .cropped-image--sizer{
  min-height: 180px
}