.pwaalert-container {
  width: 100vw;
  height: 100vh;
  background:rgba(0,0,0,0.6);
  position: fixed;
  top:0;
  left:0;
  z-index: 9999;
}

.pwaalert-container .content {
  position: absolute;
  top: calc(100vh - 140px) !important;
  left: 2.5vw;
  width: 95vw;
  min-height: 60px;
  height: auto;
  background: #fff;
  font-size: 1.4rem;
  padding: 7.5px 15px;
  border-radius: 5px;
  margin: 0;
  top: auto;
  max-width: 95vw;
}

.pwaalert-container .content .arrow{
  left: 50%;
}