.vw-eventos{
    max-width: 100vw;
    width: 100%;
}

.vw-events .wrapper-tabs-content .tab-content > .active {
    display: flex;
    flex-wrap: wrap;
}

.modal-event-detail {
    padding: 2rem 3rem;
}

.modal-event-detail .cropped-image {
    overflow: auto;
}

.modal-event-detail  .holder-image img {
    width:100%;
    height: auto;
    object-fit: contain;
    object-position: top left;
}

.vw-events .modal-container .decision-buttons button {
    font-size: 1.9rem;
}

.vw-events .parent-alert-accept-invite.modal-container > .content-wrapper {
    min-height: 24vh
}
.vw-events .parent-alert-accept-invite .content h2 {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 1rem
}