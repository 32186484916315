/* .vw-twitter{
    background-color: #f2f2f2;
}
.vw-youtube{
    background-color: #f2f2f2;
} */
.vw-social{
    background-color: #f2f2f2;
}

.vw-social{
    margin-top: 20px;    
}

.vw-social .container {
    margin:3rem 0 0 4rem;
}

.container-fluid .vw-social {
    margin:3rem 0 0 4rem;
    padding-top: .1rem;
}

/* .container-fluid .vw-social .container-fluid {
    max-width: calc(82vw - 30px) !important;
    margin-left:2;
} */
.container-fluid .vw-social {
    margin-left:0;
}

.vw-social .page-title{
    margin-bottom: 0.4rem;
    margin-left:1rem;
    margin-top:3.5rem;
}
.txt-twitter-h {
    color: #0199da;
}
.vw-YoutubeComponent-social{
    margin:4rem 0 0 -5.5rem;
}

/* .social-component2 .slick-dots {
    margin-left: -5vw !important;
  } */
  .social-component2   .btn-Social {
    position: relative;
    left: -0.1rem !important;
   }
   .vw-social .vw-date-info{
    /* font-family: VWHeadOffice; */
    font-size: 1.05rem;
    font-weight: normal;
    letter-spacing: -.2px;
    color: #c7c7c7;
    -webkit-font-smoothing: antialiased;
   }
   .vw-social .title-social{
    margin-left: 4.5rem;
   }

@media(max-width:1440px){
    .social-component2   .btn-Social {
        position: relative;
        left: -0.3rem;
       }
       /* .social-component2  .slick-dots {
        margin-left: -10vw;
      } */
  }
  /* @media(max-width:1366px){
    .container-fluid .slick-dots {
      width: calc(100% - 0.9rem) ;
  }
  } */
@media(max-width:1280px){
    .social-component2  .btn-Social {
        position: relative;
        left: -0.3rem;
       }
       /* .social-component2  .slick-dots {
        margin-left: -5vw;
      } */
  }

  @media(max-width:1024px){
    .social-component2  .btn-Social {
        position: relative;
        left: -0.3rem;
       }
       /* .social-component2  .slick-dots {
        margin-left: 1vw !important;
      } */
      .vw-social .col-lg-3 {
        flex: 0 0 47%;
        max-width: 100%;
    }
  }
  @media(max-width:992px){
    .social-component2  .btn-Social {
        position: relative;
        left: 0.5rem;
       }
       /* .social-component2  .slick-dots {
        margin-left: -0.7vw;
      } */
      .vw-social .col-lg-3 {
        flex: 0 0 47%;
        max-width: 100%;
    }
    .vw-social {
        padding-top:1rem;
    }
    .vw-social .title-social{
        margin-left: 3rem;
       }
  }
  @media(max-width:960px){
    .social-component2  .btn-Social {
        position: relative;
        left: 1.1rem;
       }
       /* .social-component2  .slick-dots {
        margin-left: 2vw;
      } */
  }
  @media(max-width:768px){
    .social-component2  .btn-Social {
        position: relative;
        left: -0.4rem;
       }
       /* .social-component2  .slick-dots {
        margin-left: -3vw !important;
      } */
  }
  @media(max-width:544px){
    .vw-social .col-12 {
        flex: 0 0 95%;
        max-width: 100%;
    }
    .vw-social  .vw-date-info {
        width: 53% !important;
    }
    .vw-social .btn-primary {
        margin-left: 1rem;
       }
 
}



