.vw-newsletter .vw-text-body {
    -webkit-font-smoothing: antialiased;
}

.vw-newsletter .btn-primary {
    width: 210px;
    margin-top: 4rem;
    margin-bottom: 3rem;
}

.vw-loanrequest .btn-primary {
    min-width: 175px;
}
.vw-loanrequest .form-check-input {
    left: 0;
}

.vw-loanrequest .btn-primary:disabled:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.vw-loanrequest .form-check-label {
    margin-left: 2rem;
    margin-top: .5rem;
}

/* BTN ATUALIZAR */

.btnAtualizar {
    font-family: VWHeadOffice-Bold;
    font-size: 1.4rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #0099d7;
    border-radius: 25px;
    float: right;
    height: auto;
    width: auto;
    border: 0px;
    margin-top: 10px;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(230, 230, 230, 0.5);
    padding: 1.3rem 3rem;
}

/* .btnAtualizar:active {
    box-shadow: -3px 2px 0.5px 1px rgba(0, 0, 0, 0.20);
    transform: translateY(4px);
} */

.btnAtualizar:focus {
    outline: 0;
}

.btnAtualizar span {
    font-size: 12px;
    align-self: flex-end;
}

/* FIM BTN ATUALIZAR */

/* BTN SECONDARY */
.margin-bottom-50{
    margin-bottom: 50px;
}

/*FIM  BTN SECONDARY */

.vw-loanrequest .page-title {
    padding-top: 10px;
}

@media (max-width: 544px) {
    .vw-loanrequest .page-title {
        font-size: 3rem;
    }
}