.vw-searchComponent{
    /* background: #f7f7f7; */
    z-index: 200;
    position: relative;
    /* margin-top: 7rem;  */
}
.vw-header-search-component {
    width: 1050px;
    margin-bottom: 2rem;
    /* width: 100%; */ 
}
/* txtClick */
.search-txt{
    padding-bottom: 1.5rem;
    margin-bottom: 0;
    border-bottom: solid  #666666b2 1px;
}
.vw-searchComponent .active .search-txt{
    background: #ffffff;
    border-bottom: solid  #0199da 2px;
}

.vw-searchComponent .vw-page-subtitle:active{
    background: #ffffff;
    border-bottom: solid  #0199da 2px;
}
.vw-searchComponent .vw-page-subtitle:hover{
    background: #ffffff;
    border-bottom: solid  #0199da 2px;
}
.vw-searchComponent .vw-page-subtitle:click{
    background: #ffffff;
    border-bottom: solid  #0199da 2px;
}

.txt-center{
    text-align: center;
    margin: 2rem 0 0rem 0rem;
}
/* .vw-header-icons {
    z-index: 0;
} */
.vw-listaSearch{
    background: #f7f7f7;
    width: 100vw;
}
.wrapper-tabs {
    border-bottom: solid  #dee2e6 1px;
}

.wrapper-tabs .nav-tabs {
    border-bottom-width: 0;
}
.wrapper-tabs .nav-tabs .nav-link {
    padding: 1.5rem 0;
}
.wrapper-tabs .nav-tabs .nav-link:hover {
   border-color: #fff;
   border-bottom-color: #dee2e6;
}
.wrapper-tabs .nav-tabs .nav-link.active {
    border-width:1px 0 2px 0;
    border-color: #0199da;
    border-top-color: #fff;
    color:#0199da;
}
.wrapper-tabs .nav-tabs .nav-link .nav-link-item {
    border:1px solid #f7f7f7;
    border-width: 0px 0 0 1px;
    width:100%;
    display: inline-block;
    padding: 0 2rem;
}
.wrapper-tabs .nav-tabs .nav-item:last-child .nav-link-item {
    border-width: 0px 1px 0 1px;
}
.search-input{
    top:.5rem;
    left: auto;
    right: 30px;
    position: absolute;
}
.xsearch-input{
    left: 50rem;
    top:-4.3rem;
    position: relative;
}
.search-container{
    background: #ffffff;
}

.vw-header-search-field .vw-header-search-input {
    padding-left: 20px;
}

.vw-search-autocomplete {
    width: 100%;
    max-width: 100%;
    position: absolute;
    top:54px;
    left:2rem;
    background: #fff;
    padding: 1.5rem;
    border:1px solid #0199da;
    border-radius: 5px;
    overflow: visible;
}
.vw-search-autocomplete .list{
    overflow: auto;
    padding-left: 0;
}
.vw-search-autocomplete .item {
    font-size: 1.3rem;
    display: block;
    padding: 1rem 0;
    border-bottom: solid  rgba(0,0,0,0.1) 1px;
}
.vw-search-autocomplete .arrow {
   top:-.6rem;
}

.vw-search-autocomplete .arrow::before {
    border-bottom-color: #0199da !important
 }


 .wrapper-tabs-content .tab-content{
    padding: 5rem 3rem 3rem 0;
 }

 .wrapper-tabs-content .tab-content .newsItem-container {
    width: 100%;
    margin-bottom: 2rem !important;
    padding-left:0;
 }
@media(max-width:1206px){
    .vw-header-search-component {
        width: 900px;
    }  
}
@media(max-width:1206px){
    .vw-header-search-component {
        width: 800px;
    }  
}
@media(max-width:1024px){
    .txt-center{
        text-align: center;
        margin: 2rem 0 0rem 8rem;
    }
    .vw-listaSearch{
        margin-left: 8rem;
    }
    
    
}
@media(max-width:768px){
    .txt-center{
        text-align: center;
        margin: 2rem 0 0rem 8rem;
    }
    .vw-listaSearch{
        margin-left: 1rem;
    }
}
@media(max-width:544px){
    .vw-header-search-component {
        padding-left: 0;
        padding-right: 0;
    }
    .vw-listaSearch {
        margin-left:0;
    }
    .vw-listaSearch > .container {
        padding:0
    }

    .vw-listaSearch .tituloResult {
        font-size: 3rem;
        padding:10px 0;
    }

    .vw-listaSearch .vw-header-search-input {
        padding-left: 20px;
        padding-right: 20px;
    }

    .search-input {
        right:10px;
    }

    .wrapper-tabs-content .tab-content {
        padding: 2rem .5rem .5rem;
    }
    .wrapper-tabs .container {
       overflow-x: scroll;
       overflow-y: hidden;
    }
    .wrapper-tabs .nav-tabs {
        width: auto;
        width: 900px;
    }
    .wrapper-tabs .nav-tabs .nav-link {
        padding: 1.5rem 0 3.5rem 0;
    }
    .wrapper-tabs .nav-tabs .nav-item {
        display: inline-block;
    }
}
@media(max-width:375px){
    .txt-center{
        text-align: center;
        margin: 2rem 0 0rem 6rem;
    }
    .vw-listaSearch{
        /* margin-left: 1rem; */
    }
}





